<template>
  <transition name="slide-fade">
    <div v-show="goTopShow" class="page-up" @click="goTop">
      <img :src="imgurl" alt=" " />
    </div>
  </transition>
</template>
<script>
export default {
  name: 'BackTop',
  props: {
    package: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgurl: require('@/assets/gobackTop.png'),
      pack: this.package,
      scrollTop: null,
      scrollHeight: null,
      clientHeight: null,
      topShow: false
    }
  },
  computed: {
    goTopShow() {
      return this.topShow
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, false)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getScrollTop() {
      const scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      return scrollTop
    },
    setScrollTop(scroll_top) {
      document.documentElement.scrollTop = scroll_top
      window.pageYOffset = scroll_top
      document.body.scrollTop = scroll_top
    },
    handleScroll() {
      this.scrollTop = this.getScrollTop()
      if (this.scrollTop > 200) {
        this.topShow = true
      } else {
        this.topShow = false
      }
    },
    goTop() {
      const _this = this
      const timer = setInterval(() => {
        const ispeed = Math.floor(-_this.scrollTop / 5)
        _this.scrollTop <= 1
          ? (_this.scrollTop = 0)
          : (document.documentElement.scrollTop = document.body.scrollTop =
              _this.scrollTop + ispeed)
        if (_this.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    }
  }
}
</script>

<style lang="less">
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.3, 0.8, 1);
  opacity: 0;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active 在低于 2.1.8 版本中 */ {
  // transform: translateY(-20px);
  opacity: 0;
}
.page-up {
  position: fixed;
  right: 50px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 4px 4px 2px rgba(12, 12, 13, 0.1);
  opacity: 1;
  z-index: 100;
  .el-icon-caret-top {
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
  }
  p {
    display: none;
    text-align: center;
    color: #fff;
  }
  &:hover {
    opacity: 1;
  }
}
.page-up img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>
