export function setCookie(name, value) {
  var exp = new Date();
  exp.setTime(exp.getTime() + 1 * 30 * 60 * 1000);
  window.document.cookie =
  encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";expires=" + exp.toGMTString()+ ";path=/";
  // localStorage.setItem('savetoken2', value);
}
export function getCookie(name) {
  var value = decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(name).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
  return value
  // var arr,
  //   reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

  // if ((arr = window.document.cookie.match(reg))) return unescape(arr[2]);
  // else return null;
  // return localStorage.getItem('savetoken2');
}
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1 * 30 * 60 * 1000);
  var cval = "del";
  if (cval != null)
    window.document.cookie =
    encodeURIComponent(name) + "=" + cval + ";expires=" + exp.toGMTString()+ ";path=/";
  // localStorage.removeItem('savetoken2');
}
