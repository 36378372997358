<template>
  <div class="main">
    <div class="card">
      <div class="cardBg">
        <div class="photo">
          <img :src="data.wechatAvatar ? data.wechatAvatar : imgurl" alt=" " class="photoimg" />
        </div>

        <div class="doctorDetail">
          <!-- 左侧信息 -->
          <div class="msgBox">
            <div
              class="text_style"
            >
              <span>姓名：</span>
              <span>{{ data.name }}</span>
            </div>
            <div
              class="text_style"
            >
              <span>MDM Code：</span>
              <span>{{ data.mdmCode }}</span>
            </div>
            <div
              class="wechat_txt"
            >
              <span>协议状态：</span>
              <span v-if="data.travelConsent" style="color:#1B4298;">已确认</span>
              <span v-else>未确认</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    qrCode: Boolean,
  },
  data() {
    return {
      isShowEWM: false,
      imgurl: require('@/assets/doctor.png'),
    }
  },
  methods: {
  },
}
</script>
<style lang="less">
.EWM {
  width: 50px;
  height: 50px;
  background: red;
}
.cardBg {
  width: 9rem - 13/37.5rem;
  padding: 0 0 0 13/37.5rem;
  margin: 0;
  display: flex;
}
.doctorDetail {
  flex: 1;
  margin: 0 0 0 10px;
  // padding-bottom: 10px;
  padding-bottom: 0.347rem;
}
.hospitalCard :not(:last-child) .cardBg {
  border-bottom: 1px solid #e4e7ed;
}
.hospitalCard :not(:last-child) .doctorDetail {
  margin: 0 0 0 10px;
  // padding-bottom: 10px;

}
.card {
  text-align: left;
  padding: 0 0 15/37.5rem 0;
}
.msgBox div {
  // width:100/37.5rem;
  font-size: 12/37.5rem;
  margin: 0 0 5/37.5rem 0;
  // display:inline-block;
}
.msgBox {
  display: inline-block;
  vertical-align: top;
  // width: 3.2rem;
  margin-left: 0.3rem;
}
.msgBox > :first-child {
  display: flex;
  flex-direction: row;
  // justify-content:flex-start;
  // align-items: flex-start;
}
.msgBox > :first-child > span {
  white-space: nowrap;
}
.msgBox > :first-child p {
  padding: 0;
  margin: 0;
}
.btnBox {
  text-align: center;
  // display: inline-block;
  vertical-align: top;
  float: right;
  margin: 0 12/37.5rem 0 0;
}
.deleteImg {
  float: right;
  font-size: 12/37.5rem;
  display: block;
  color: #1B4298;
}
.phone_qrcode{
  float: unset;
  text-decoration: underline;
  font-size: 0.25rem;
  display: inline-block;
  white-space: nowrap;
  margin-top: 2px;
}
.btnBox button {
  display: block;
  width: 80/37.5rem;
  height: 23/37.5rem;
  line-height: 0.513333rem;
  margin: 0 0 8/37.5rem 0;
  font-size: 12/37.5rem;
  border: 1px solid #1B4298;
  border-radius: 4px;
  background: #1B4298;
  color: white;
}
.btnBox .button_actice{
  background-color:#1B4298;
  color:#fff
}
.btnBox .addToUntargetBtnImg {
  width: 42px;
  margin-top: 10px;
  background: #ffffff;
}
.btnBox .add_unconsert_doctor{
  font-size:12px;
  font-weight:400;
  color:#333333;
}
.EWMBTN_box{
  width: 182.475/37.5rem;
  display:inline-block
}
.EWMBTN {
  width: 96/37.5rem !important;
  height: 20/37.5rem !important;
  line-height: 0.43333rem;
  border-radius: 4px !important;
  background-color: #1B4298 !important;
  color: #fff !important;
  font-size: 12/37.5rem !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
}
.photo {
  width: 70/37.5rem;
  height: 83/37.5rem;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
}
.photoimg {
  object-fit: cover;
  vertical-align: top;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
  border-radius: 5px;
}
.btnBox .SJYZ {
  margin: 0;
}
.msgBox .text_style{
  display:inline-block;
  width: 100%;
  margin-bottom:0px;
  font-size:0.32rem;
  white-space:nowrap;
  text-overflow: inherit!important;
  overflow: inherit!important;
}
.wechat_txt{
  float: left;
  margin-left:0.32rem;
  display:inline-block;
  width:100%;
  font-size:0.32rem;
  white-space:nowrap;
  text-overflow: inherit!important;
  overflow: inherit!important;
}
.is_add_doctor{
  font-size:16px;
  color:#1B4298;
}
.mint-msgbox-btns button{
  font-size: 0.374rem;
}
</style>
