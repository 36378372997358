var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"cardBg"},[_c('div',{staticClass:"photo"},[_c('img',{staticClass:"photoimg",attrs:{"src":_vm.data.wechatAvatar ? _vm.data.wechatAvatar : _vm.imgurl,"alt":" "}}),(
            _vm.$store.getters.showTargetSwitch &&
              _vm.$route.path.indexOf('/RcDoctor') == -1&&
              _vm.$route.path.indexOf('/UntargetDoctor') == -1
          )?_c('div',{staticClass:"deleteImgDiv",staticStyle:{"text-align":"center"}},[(!_vm.data.mobile)?_c('span',{staticClass:"deleteImg phone_qrcode",on:{"click":function($event){$event.stopPropagation();return _vm.phoneQrcodeClick(_vm.data)}}},[_vm._v("手机知情同意")]):_c('span',{staticClass:"deleteImg phone_qrcode",staticStyle:{"color":"gray"}},[_vm._v("手机知情同意")]),_c('div',{staticClass:"deleteImg",staticStyle:{"width":"100%","text-align":"center","color":"black","font-size":"0.20rem","display":"inline-block","white-space":"nowrap"}},[_vm._v(" 非绑定欧家医荟 ")])]):_vm._e()]),_c('div',{staticClass:"doctorDetail"},[_c('div',{staticClass:"msgBox"},[_c('div',{staticClass:"text_style"},[_c('span',[_vm._v("姓名：")]),_c('span',[_vm._v(_vm._s(_vm.data.name))])]),_c('div',{staticClass:"text_style"},[_c('span',[_vm._v("科室：")]),_c('span',[_vm._v(_vm._s(_vm.data.department))])]),_c('div',{staticClass:"text_style"},[_c('span',[_vm._v("职位：")]),_c('span',[_vm._v(_vm._s(_vm.data.positionTitle))])]),(_vm.data.validateState == 'validated')?_c('div',{staticClass:"wechat_txt"},[_c('span',[_vm._v("微信名：")]),_c('span',[_vm._v(_vm._s(_vm.data.nickName))])]):_vm._e(),(
              _vm.$route.path.indexOf('/doctor') != -1 ||
                _vm.$route.path.indexOf('/UntargetDoctor') != -1
            )?_c('div',{staticClass:"wechat_txt"},[_c('span',[_vm._v("实名状态：")]),(_vm.data.consentState == '1')?_c('span',{staticStyle:{"color":"#1B4298"}},[_vm._v("已认证")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v("未认证")])]):_vm._e(),(
              !_vm.$store.getters.showTargetSwitch &&
                _vm.$store.getters.mdmCode.indexOf(_vm.data.mdmCode) != -1
            )?_c('div',[_c('p',{staticClass:"is_add_doctor"},[_vm._v("该客户已添加")])]):_vm._e()]),(
            _vm.$store.getters.showTargetSwitch || !_vm.$store.getters.searchStatus
          )?_c('div',{staticClass:"btnBox"},[(_vm.data.isFollow == 0)?_c('button',{staticClass:"GZ",attrs:{"disabled":""}},[_vm._v(" 未关注 ")]):_vm._e(),(_vm.data.isFollow == 1)?_c('button',{staticClass:"button_actice",attrs:{"disabled":""}},[_vm._v(" 已关注 ")]):_vm._e(),(_vm.data.validateState !== 'validated')?_c('button',{staticClass:"BD",attrs:{"disabled":""}},[_vm._v(" 未绑定 ")]):_vm._e(),(_vm.data.validateState == 'validated')?_c('button',{staticClass:"button_actice",attrs:{"disabled":""}},[_vm._v(" 已绑定 ")]):_vm._e(),(
              _vm.$store.getters.showTargetSwitch &&
                _vm.data.validateState !== 'validated'
            )?_c('div',{staticClass:"EWMBTN_box",staticStyle:{"width":"2.133rem"}},[(_vm.data.validateState !== 'validated' && _vm.qrCode)?_c('mt-button',{staticClass:"EWMBTN",staticStyle:{"float":"right","width":"2.433rem !important","box-shadow":"rgb(24 66 152) 1px 1px 2px 1px","height":"0.733333rem !important","line-height":"0.753333rem !important"},nativeOn:{"click":function($event){return _vm.EWMBTNClick(_vm.data)}}},[_vm._v("生成二维码")]):_vm._e(),_c('div',{staticClass:"deleteImg",staticStyle:{"width":"100%","text-align":"center","color":"black","font-size":"0.20rem","display":"inline-block","white-space":"nowrap","margin-top":"0.15rem"}},[_vm._v(" 用于绑定欧家医荟 ")])],1):_vm._e()]):_vm._e(),(
            !_vm.$store.getters.showTargetSwitch &&
              !(_vm.$store.getters.mdmCode.indexOf(_vm.data.mdmCode) != -1) &&
              _vm.$store.getters.searchStatus
          )?_c('div',{staticClass:"btnBox"},[_c('img',{staticClass:"addToUntargetBtnImg",attrs:{"src":require("@/assets/addWhiteBtn.png"),"alt":"添加至我的列表"},on:{"click":function($event){$event.stopPropagation();return _vm.addToUntargetList(_vm.data)}}}),_c('p',{staticClass:"add_unconsert_doctor"},[_vm._v("添加至我的列表")])]):_vm._e(),(_vm.isShowEWM)?_c('div',{staticClass:"EWM"}):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }