<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('messageStore', JSON.stringify(this.$store.state))
    })
    localStorage.getItem('messageStore') && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem('messageStore'))))
  },
  methods: {

  }
}
</script>

<style>
body{
  margin:0;
    font-size:.4rem;
    background: #F5F7FA /* margin-top: 60px;;;;; */;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  /* font-family: PingFangSC-Semibold,PingFang SC; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-alig{
    n: center; */
  /* color: #2c3e50; */
  height: 100%;
  width:100%;
  background:#F5F7FA

  /* margin-top: 60px; */

}
.col-m1 {
  color:black;
}
.col-m2 {
  color:#999999;
}
.col-line {
  color: #E0E0E4
}
.col-m3 {
  color:white;
}
.col-b1 {
  background-color: black;
}
.col-b2 {
  background-color: rgba(245,247,250,1);
}
.col-b3 {
  background-color: white;
}
body,html {
  height: 100%;
}
.mu-appbar{
  -webkit-box-shadow:0 0 0 0 !important;
  border-bottom: 1px solid #DBDBDB;
}
input::-ms-clear {
display: none;
}
input::-ms-reveal {
display: none;
}
/*清除谷歌浏览器下的 search 叉号 */
input[type=search]::-webkit-search-cancel-button {
-webkit-appearance: none;
}
/*清除IE下的 search 叉号 */
input[type=search]::-ms-clear {
display: none;
}
.mint-indicator-wrapper{
  padding: 0.533rem!important;
}
.mint-indicator-wrapper .mint-indicator-spin .mint-spinner-fading-circle{
  width: 0.85333rem!important;
  height: 0.85333rem!important;
}
.mint-indicator-wrapper .mint-indicator-text{
  margin-top: 0.26666rem;
  font-size: 0.426666rem;
}
.mint-msgbox-btns .mint-msgbox-confirm {
    color: #1B4298!important;
    width: 50%;
}
.mint-msgbox-btns .mint-msgbox-cancel {
    position: absolute;
    /* left: 0; */
    top: 0.1rem!important;
    right: 0.1rem!important;
    font-size: 0.86rem!important;
    color: #909399;
    width: 0.86rem!important;
    border-right: none!important;
    /* width: 50%; */
    /* border-right: 0.026667rem solid #ddd; */
}
.mint-button::after{
  background-color: #1B4298;
  color: #fff;
}
.mint-button:not(.is-disabled):active::after {
  opacity: 0;
}
.el-input__suffix-inner {
  opacity: 0;
}
.el-form-item__content{
  width: 75%;
  float: right;
}
#rc_form .el-form-item__content{
  width: 100%!important;
}
.el-collapse {
    border-top: 0.026667rem solid #F5F7FA;
    border-bottom: 0.026667rem solid #F5F7FA;
}
.el-collapse-item__header{
  height: 1rem;
  line-height: 1rem;
  border-bottom: 0.126667rem solid #F5F7FA;
}
.el-collapse-item__wrap{
  border-bottom: 0.126667rem solid #F5F7FA;
  border-top: 0.126667rem solid #F5F7FA;
}
.el-collapse-item__content{
  padding-bottom: 0;
}
.el-radio__label {
  /* padding-left: 0px; */
  display: inline-block!important;
}
.el-input--suffix .el-input__inner {
  padding-right: 0.2rem;
}
.mint-spinner-snake{
  width: 12px!important;
  height: 12px!important;
}
</style>
