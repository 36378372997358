<template>
  <div class="main">
    <div class="card">
      <div class="cardBg">
        <div class="photo">
          <img
            :src="data.wechatAvatar ? data.wechatAvatar : imgurl"
            alt=" "
            class="photoimg"
          />
          <!-- <div class="deleteImgDiv" style="text-align:center;" v-if="$store.getters.showTargetSwitch&&$route.path.indexOf('/RcDoctor')==-1&&!($store.getters.showUntargetDoctor&&$store.getters.showTargetSwitch)"> -->
          <div
            class="deleteImgDiv"
            style="text-align:center;"
            v-if="
              $store.getters.showTargetSwitch &&
                $route.path.indexOf('/RcDoctor') == -1&&
                $route.path.indexOf('/UntargetDoctor') == -1
            "
          >
            <span
              class="deleteImg phone_qrcode"
              v-if="!data.mobile"
              @click.stop="phoneQrcodeClick(data)"
              >手机知情同意</span
            >
            <span class="deleteImg phone_qrcode" style="color:gray;" v-else
              >手机知情同意</span
            >
            <div
              class="deleteImg"
              style="width:100%;text-align:center;color:black;font-size: 0.20rem;display: inline-block;white-space: nowrap;"
            >
              非绑定欧家医荟
            </div>
          </div>
        </div>

        <div class="doctorDetail">
          <!-- 左侧信息 -->
          <div class="msgBox">
            <div class="text_style">
              <span>姓名：</span>
              <span>{{ data.name }}</span>
            </div>
            <div class="text_style">
              <span>科室：</span>
              <span>{{ data.department }}</span>
            </div>
            <div class="text_style">
              <span>职位：</span>
              <span>{{ data.positionTitle }}</span>
            </div>
            <!-- <div class="EWMBTN_box" v-if="$store.getters.showTargetSwitch&&data.validateState!=='validated'&&($store.getters.showUntargetDoctor&&$store.getters.showTargetSwitch)">
              <mt-button
                v-if="data.validateState !=='validated' && qrCode "
                class="EWMBTN"
                @click.native="EWMBTNClick(data)"
              >生成二维码</mt-button>
            </div> -->
            <div v-if="data.validateState == 'validated'" class="wechat_txt">
              <span>微信名：</span>
              <span>{{ data.nickName }}</span>
            </div>
            <div
              class="wechat_txt"
              v-if="
                $route.path.indexOf('/doctor') != -1 ||
                  $route.path.indexOf('/UntargetDoctor') != -1
              "
            >
              <span>实名状态：</span>
              <span v-if="data.consentState == '1'" style="color:#1B4298;"
                >已认证</span
              >
              <span v-else style="color:red;">未认证</span>
            </div>
            <div
              v-if="
                !$store.getters.showTargetSwitch &&
                  $store.getters.mdmCode.indexOf(data.mdmCode) != -1
              "
            >
              <p class="is_add_doctor">该客户已添加</p>
            </div>
          </div>
          <!-- 右侧关注状态 -->
          <div
            v-if="
              $store.getters.showTargetSwitch || !$store.getters.searchStatus
            "
            class="btnBox"
          >
            <button v-if="data.isFollow == 0" class="GZ" disabled>
              未关注
            </button>
            <button v-if="data.isFollow == 1" class="button_actice" disabled>
              已关注
            </button>
            <button
              v-if="data.validateState !== 'validated'"
              class="BD"
              disabled
            >
              未绑定
            </button>
            <button
              v-if="data.validateState == 'validated'"
              class="button_actice"
              disabled
            >
              已绑定
            </button>
            <!-- <div class="EWMBTN_box" style="width:2.133rem" v-if="$store.getters.showTargetSwitch&&data.validateState!=='validated'&&!($store.getters.showUntargetDoctor&&$store.getters.showTargetSwitch)"> -->
            <div
              class="EWMBTN_box"
              style="width:2.133rem"
              v-if="
                $store.getters.showTargetSwitch &&
                  data.validateState !== 'validated'
              "
            >
              <mt-button
                v-if="data.validateState !== 'validated' && qrCode"
                class="EWMBTN"
                style="float: right;width: 2.433rem !important;box-shadow: rgb(24 66 152) 1px 1px 2px 1px;height: 0.733333rem !important;line-height: 0.753333rem !important;"
                @click.native="EWMBTNClick(data)"
                >生成二维码</mt-button
              >
              <div
                class="deleteImg"
                style="width:100%;text-align:center;color:black;font-size: 0.20rem;display: inline-block;white-space: nowrap;margin-top: 0.15rem;"
              >
                用于绑定欧家医荟
              </div>
            </div>
          </div>
          <!-- 添加至我的列表按钮 -->
          <div
            v-if="
              !$store.getters.showTargetSwitch &&
                !($store.getters.mdmCode.indexOf(data.mdmCode) != -1) &&
                $store.getters.searchStatus
            "
            class="btnBox"
          >
            <img
              src="@/assets/addWhiteBtn.png"
              class="addToUntargetBtnImg"
              alt="添加至我的列表"
              @click.stop="addToUntargetList(data)"
            />
            <p class="add_unconsert_doctor">添加至我的列表</p>
          </div>
          <div v-if="isShowEWM" class="EWM"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator } from "mint-ui";
import { deleteUntargetHcp } from "@/api/api.js";
import { postUnLikeHcpList } from "@/api/api.js";
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    qrCode: Boolean
  },
  data() {
    return {
      isShowEWM: false,
      imgurl: require("@/assets/doctor.png")
    };
  },
  methods: {
    EWMBTNClick(param) {
      const html = `<div style="text-align:justify;line-height:1.3;text-indent:2em;color:#888888;font-size:0.373rem;">本人清楚了解所申请生成的二维码为对应客户的专属二维码，并在此确认该专属二维码只会提供给该对应客户扫码使用。</br></br></div>
      <div style="text-align:justify;line-height:1.3;text-indent:2em;color:#888888;font-size:0.373rem;">若本人有任何故意/过失行为或误用行为而导致该二维码被非对应客户扫码使用，本人清楚明白该等行为属于违纪行为，并将受到公司相关的纪律处分。</div>`;
      this.$messagebox({
        $type: "prompt",
        title: "提示",
        message: html,
        closeOnClickModal: false, //点击model背景层不关闭MessageBox
        showCancelButton: true, //显示取消按钮
        confirmButtonText: "确认",
        cancelButtonText: "×",
        autofocus: false
      })
        .then(({ value, action }) => {
          this.$store.dispatch("setShowTargetSwitch", false);
          this.$store.dispatch("setSearchStatus", false);
          sessionStorage.setItem("doctorData", JSON.stringify(param));
          if (this.$route.path.indexOf("/RcDoctor") != -1) {
            this.$router.push({
              name: "createdRcQRCode",
              query: { path: this.$route.name }
            });
          } else if (this.$route.path.indexOf("/UntargetDoctor") != -1) {
            this.$router.push({
              name: "UntargetQRCode",
              query: { path: this.$route.name }
            });
          } else {
            this.$router.push({
              name: "createdQRCode",
              query: { path: this.$route.name }
            });
          }
        })
        .catch(err => {});
    },
    phoneQrcodeClick(param) {
      const html = `<div style="text-align:justify;line-height:1.3;text-indent:2em;color:#888888;font-size:0.373rem;">本人清楚了解所申请生成的二维码为对应客户的专属二维码，并在此确认该专属二维码只会提供给该对应客户扫码使用。</br></br></div>
      <div style="text-align:justify;line-height:1.3;text-indent:2em;color:#888888;font-size:0.373rem;">若本人有任何故意/过失行为或误用行为而导致该二维码被非对应客户扫码使用，本人清楚明白该等行为属于违纪行为，并将受到公司相关的纪律处分。</div>`;
      this.$messagebox({
        $type: "prompt",
        title: "提示",
        message: html,
        closeOnClickModal: false, //点击model背景层不关闭MessageBox
        showCancelButton: true, //显示取消按钮
        confirmButtonText: "确认",
        cancelButtonText: "×",
        autofocus: false
      })
        .then(({ value, action }) => {
          this.$store.dispatch("setShowTargetSwitch", false);
          this.$store.dispatch("setSearchStatus", false);
          sessionStorage.setItem("doctorData", JSON.stringify(param));
          if (this.$route.path.indexOf("/UntargetDoctor") != -1) {
            this.$router.push({
              name: "createdUntargetPhoneQRCode",
              query: { path: this.$route.name }
            });
          } else {
            this.$router.push({
              name: "createdPhoneQRCode",
              query: { path: this.$route.name }
            });
          }
        })
        .catch(err => {});
    },
    addToUntargetList(param) {
      const html = `<div style="text-align:center;color:#888888;font-size:0.373rem;">是否确认添加该客户</div>`;
      this.$messagebox({
        $type: "prompt",
        title: "提示",
        message: html,
        closeOnClickModal: false, //点击model背景层不关闭MessageBox
        showCancelButton: true, //显示取消按钮
        confirmButtonText: "确认",
        cancelButtonText: "×",
        autofocus: false
      })
        .then(action => {
          Indicator.open({
            text: "数据加载中...",
            spinnerType: "fading-circle"
          });
          const data = {
            // mdmCode: param.mdmCode,
            hcpMdmCode: param.mdmCode
          };
          postUnLikeHcpList(data)
            .then(res => {
              if (res === "超时") {
                Indicator.close();
                this.netWork = true;
                this.filterData = false;
                this.serviceErr = false;
                return;
              }
              const data = res.data;
              if (data && data.status === 1) {
                this.netWork = false;
                this.serviceErr = false;
                this.$store.dispatch("setMdmCode", param.mdmCode);
                Indicator.close();
              } else {
                Indicator.close();
                if (data.code == 20021) {
                  this.$messagebox.alert(
                    `<div style="text-align:center;line-height:1;color:#888888;font-size:14px;">该客户已被他人添加</div>`,
                    "提示"
                  );
                } else {
                  this.$messagebox.alert(
                    `<div style="text-align:center;line-height:1;color:#888888;font-size:14px;">服务器繁忙，请稍后再试</div>`,
                    "提示"
                  );
                }
              }
            })
            .catch(error => {
              Indicator.close();
              this.filterData = false;
              this.netWork = false;
              this.serviceErr = true;
            });
        })
        .catch(error => {});
    },
    deleteUntargetDoctor(param) {
      const html = `<div style="text-align:center;color:#888888;font-size:0.373rem;">是否确认删除该客户</div>`;
      this.$messagebox({
        $type: "prompt",
        title: "提示",
        message: html,
        closeOnClickModal: false, //点击model背景层不关闭MessageBox
        showCancelButton: true, //显示取消按钮
        confirmButtonText: "确认",
        cancelButtonText: "×",
        autofocus: false
      })
        .then(action => {
          Indicator.open({
            text: "数据加载中...",
            spinnerType: "fading-circle"
          });
          param.isDelete = true;
          const data = {
            mdmCode: param.mdmCode
          };
          deleteUntargetHcp(data)
            .then(res => {
              if (this.$route.path.indexOf("/RcDoctor") != -1) {
                this.$router.push({
                  name: "RcDoctor",
                  query: { path: "/RcDoctor" }
                });
              } else {
                this.$router.push({
                  name: "doctor",
                  query: { path: "/doctor" }
                });
              }
              this.$store.dispatch(
                "setReloadList",
                !this.$store.getters.reloadList
              );
              this.$store.dispatch("delMdmCode", param.mdmCode);
              Indicator.close();
            })
            .catch(error => {
              Indicator.close();
            });
        })
        .catch(error => {
          Indicator.close();
        });
    }
  }
};
</script>
<style lang="less">
.EWM {
  width: 50px;
  height: 50px;
  background: red;
}
.cardBg {
  width: 9rem - 13/37.5rem;
  padding: 0 0 0 13/37.5rem;
  margin: 0;
  display: flex;
}
.doctorDetail {
  flex: 1;
  margin: 0 0 0 10px;
  // padding-bottom: 10px;
  padding-bottom: 0.247rem;
}
.hospitalCard :not(:last-child) .cardBg {
  border-bottom: 1px solid #e4e7ed;
}
.hospitalCard :not(:last-child) .doctorDetail {
  margin: 0 0 0 10px;
  // padding-bottom: 10px;
}
.card {
  text-align: left;
  padding: 0 0 15/37.5rem 0;
}
.msgBox div {
  // width:100/37.5rem;
  font-size: 12/37.5rem;
  margin: 0 0 5/37.5rem 0;
  // display:inline-block;
}
.msgBox {
  display: inline-block;
  vertical-align: top;
  width: 3.2rem;
  margin-left: 0.3rem;
}
.msgBox > :first-child {
  display: flex;
  flex-direction: row;
  // justify-content:flex-start;
  // align-items: flex-start;
}
.msgBox > :first-child > span {
  white-space: nowrap;
}
.msgBox > :first-child p {
  padding: 0;
  margin: 0;
}
.btnBox {
  text-align: center;
  // display: inline-block;
  vertical-align: top;
  float: right;
  margin: 0 12/37.5rem 0 0;
}
.deleteImg {
  float: right;
  font-size: 12/37.5rem;
  display: block;
  color: #1b4298;
}
.phone_qrcode {
  float: unset;
  text-decoration: underline;
  font-size: 0.25rem;
  display: inline-block;
  white-space: nowrap;
  margin-top: 2px;
}
.btnBox button {
  display: block;
  width: 80/37.5rem;
  height: 23/37.5rem;
  line-height: 0.513333rem;
  margin: 0 0 8/37.5rem 0;
  font-size: 12/37.5rem;
  border: 1px solid #1b4298;
  border-radius: 4px;
  background: #1b4298;
  color: white;
}
.btnBox .button_actice {
  background-color: #1b4298;
  color: #fff;
}
.btnBox .addToUntargetBtnImg {
  width: 42px;
  margin-top: 10px;
  background: #ffffff;
}
.btnBox .add_unconsert_doctor {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.EWMBTN_box {
  width: 182.475/37.5rem;
  display: inline-block;
}
.EWMBTN {
  width: 96/37.5rem !important;
  height: 20/37.5rem !important;
  line-height: 0.43333rem;
  border-radius: 4px !important;
  background-color: #1b4298 !important;
  color: #fff !important;
  font-size: 12/37.5rem !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
}
.photo {
  width: 70/37.5rem;
  height: 83/37.5rem;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
}
.photoimg {
  object-fit: cover;
  vertical-align: top;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
  border-radius: 5px;
}
.btnBox .SJYZ {
  margin: 0;
}
.msgBox .text_style {
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  font-size: 0.32rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wechat_txt {
  float: left;
  margin-left: 0.32rem;
  display: inline-block;
  width: 100%;
  font-size: 0.32rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.is_add_doctor {
  font-size: 16px;
  color: #1b4298;
}
.mint-msgbox-btns button {
  font-size: 0.374rem;
}
</style>
