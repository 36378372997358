var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"login col-b2"},[(_vm.$store.getters.showTargetSwitch)?_c('div',{staticClass:"switchBtn"},[_c('mt-button',{class:[
          _vm.$store.getters.showUntargetDoctor
            ? 'mint-button--default'
            : 'mintbuttonclick',
        ],on:{"click":_vm.targetDoctor}},[_vm._v("目标客户")]),_c('mt-button',{class:[
          _vm.$store.getters.showUntargetDoctor
            ? 'mintbuttonclick'
            : 'mint-button--default',
        ],on:{"click":_vm.unTargetDoctor}},[_vm._v("非目标客户")])],1):_vm._e(),(_vm.inputStatus || _vm.$store.getters.searchUntargetList)?_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"search_Input"},[_c('i',{staticClass:"mintui mintui-search"}),_c('form',{staticStyle:{"width":"100%"},attrs:{"action":"javascript:return true"}},[_c('mt-field',{attrs:{"id":"main_search2","type":"search","placeholder":"请输入客户姓名"},on:{"input":_vm.input},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.search.apply(null, arguments)},"click":function($event){return _vm.focus.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)])]):_vm._e(),(_vm.$route.meta.keepAlive)?_c('router-view',{on:{"UntargetSSLSClick":_vm.UntargetSSLSClick}}):_vm._e(),(!_vm.$route.meta.keepAlive)?_c('router-view',{on:{"UntargetSSLSClick":_vm.UntargetSSLSClick}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }