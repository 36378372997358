/*
 * @Description:
 * @Author: Ray
 * @Date: 2022-09-23 00:47:59
 * @LastEditTime: 2024-08-07 10:11:46
 * @LastEditors: Ray
 */
import request from "@/utils/request.js";

//code获取token
export function getTokenByCode(data) {
  return request({
    url: "/auth/token",
    method: "post",
    params: data
  });
}
//获取客户列表
export function getHcpList(data) {
  return request({
    url: "/pis/me/myTargetHcps",
    method: "get",
    params: data
  });
}
//获取客户详情
export function getHcpDetail(id) {
  return request({
    url: "/hcp/" + id,
    method: "get",
    params: {}
  });
}
// 生成二维码
export function createQRCode(data) {
  return request({
    url: "/pis/me/qrcode",
    method: "post",
    params: data
  });
}
// 生成手机号二维码
export function createPhoneQRCode(data) {
  return request({
    url: "/pis/mobile/qrcode",
    method: "get",
    params: data
  });
}

//获取RC客户列表
export function getRcHcpList(data) {
  return request({
    url: "/pis/me/import/rcHcps",
    method: "get",
    params: data
  });
}
//获取机酒协议客户列表
export function getTravelHcpList(data) {
  return request({
    url: "/pis/travelHcp",
    method: "get",
    params: data
  });
}
//搜索非目标客户列表
export function getUnLikeHcpList(data) {
  return request({
    url: "/pis/me/myUnconcernedHcps",
    method: "get",
    params: data
  });
}
//获取非目标客户列表
export function getUntargetHcpList(data) {
  return request({
    url: "/untarget/hcp",
    method: "get",
    params: data
  });
}
// export function getUntargetHcpList(data) {
//   return request({
//     url: "/pis/me/import/untargetHcps",
//     method: "get",
//     params: data
//   });
// }
//添加非目标客户
export function postUnLikeHcpList(data) {
  return request({
    url: "/pis/me/myUntargetHcps",
    method: "post",
    params: data
  });
}
//删除非目标客户
export function deleteUntargetHcp(data) {
  return request({
    url: "/pis/me/myUntargetHcps",
    method: "delete",
    params: data
  });
}

