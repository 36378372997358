<template lang="html">
  <div class="login">
    <!-- 登录失败-->
    <div v-if="loginStatus" class="login_box">
      <div class="login_header">
        <p>登录失败</p>
      </div>
      <div class="login_img">
        <img src="@/assets/loginError.png" alt="" />
      </div>
      <!-- 非PSR身份  非销售代表 -->
      <div v-if="isNotPSR" class="login_title">
        <p>很抱歉,您没有访问此应用的权限 如有疑问请联系My Support Center!</p>
      </div>
      <div v-if="isInactivePSR" class="login_title">
        <p>很抱歉,您的员工账户已停用,如有疑问请联系My Support Center!</p>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator } from "mint-ui";
import { getCookie } from "@/utils/cookie.js";
import { getTokenByCode } from "@/api/api.js";
export default {
  data() {
    return {
      isNotPSR: false,
      isInactivePSR: false,
      loginStatus: false,
      code: null,
      error: null,
      token: null
    };
  },
  created() {
    history.pushState(null, null, document.URL);
    var referrer = sessionStorage.getItem("referrer");
    if (!getCookie("token") || getCookie("token") === "null") {
      var url = window.location.href; //获取地址栏 url
      var index = url.indexOf("?code="); //获取#的位置
      var errorIndex = url.indexOf("error");
      const urlParams = new URLSearchParams(window.location.search);
      if (index !== -1) {
        // var loginIndex = url.indexOf("/login");
        // var referrer = sessionStorage.getItem('referrer')
        // if (referrer.indexOf("/RcDoctor") != -1) {
        //   history.pushState(
        //     null,
        //     null,
        //     document.URL.substr(0, loginIndex) + "/RcDoctor"
        //   );
        //   window.location.replace(
        //     document.URL.substr(0, loginIndex) + "/RcDoctor"
        //   );
        // } else if (referrer.indexOf("/UntargetDoctor") != -1) {
        //   history.pushState(
        //     null,
        //     null,
        //     document.URL.substr(0, loginIndex) + "/UntargetDoctor"
        //   );
        //   window.location.replace(
        //     document.URL.substr(0, loginIndex) + "/UntargetDoctor"
        //   );
        // } else if (referrer.indexOf("/AppealDoctor") != -1) {
        //   history.pushState(
        //     null,
        //     null,
        //     document.URL.substr(0, loginIndex) + "/AppealDoctor"
        //   );
        //   window.location.replace(
        //     document.URL.substr(0, loginIndex) + "/AppealDoctor"
        //   );
        // } else if (referrer.indexOf("/FormAdress") != -1) {
        //   history.pushState(
        //     null,
        //     null,
        //     document.URL.substr(0, loginIndex) + "/FormAdress"
        //   );
        //   window.location.replace(
        //     document.URL.substr(0, loginIndex) + "/FormAdress"
        //   );
        // } else if (referrer.indexOf("/AirplaneHotelDoctor") != -1) {
        //   history.pushState(
        //     null,
        //     null,
        //     document.URL.substr(0, loginIndex) + "/AirplaneHotelDoctor"
        //   );
        //   window.location.replace(
        //     document.URL.substr(0, loginIndex) + "/AirplaneHotelDoctor"
        //   );
        // } else {
        //   history.pushState(
        //     null,
        //     null,
        //     document.URL.substr(0, loginIndex) + "/doctor"
        //   );
        //   window.location.replace(
        //     document.URL.substr(0, loginIndex) + "/doctor"
        //   );
        // }
        this.code = urlParams.get("code") || null;
      } else {
        if (errorIndex !== -1) {
          this.loginStatus = true;
          if (urlParams.get("error") === "invalid_identity") {
            this.isNotPSR = true;
          }
          if (urlParams.get("error") === "inactive_employee") {
            this.isInactivePSR = true;
          }
        }
      }
      if (!this.code && !this.loginStatus) {
        this.goAuth();
      } else {
        console.log('获取到code',this.code)
        if (!this.loginStatus) {
          this.getAuthToken();
        }
      }
    } else {
      if (referrer.indexOf("/RcDoctor") != -1) {
        this.$router.push({ path: "/RcDoctor" });
      } else if (referrer.indexOf("/UntargetDoctor") != -1) {
        this.$router.push({ path: "/UntargetDoctor" });
      } else if (referrer.indexOf("/AirplaneHotelDoctor") != -1) {
        this.$router.push({ path: "/AirplaneHotelDoctor" });
      } else {
        this.$router.push({ path: "/doctor" });
      }
    }
  },
  mounted() {},
  methods: {
    goAuth() {
      const data = {
        client_id: process.env.VUE_APP_CLIENT_ID,
        grant_type: process.env.VUE_APP_GRANT_TYPE,
        request_mode: process.env.VUE_APP_REQUEST_MODE,
        // skip_registration: process.env.VUE_APP_SKIP_REGISTERATION,//默认不传
        redirect_uri: encodeURIComponent(process.env.VUE_APP_REDIRECT_URL),
        response_type: process.env.VUE_APP_RESPONSE_TYPEL,
        state: 'state'
      };
      var authurl =
        process.env.VUE_APP_AUTH_URL + "/auth/authorize?" + this.getHttpData(data);
      setTimeout(function() {
        window.location.href = authurl;
      }, 0);
      // window.location.href =
      //   process.env.VUE_APP_AUTH_URL + '/auth/authorize?' + this.getHttpData(data)
    },
    getAuthToken () {
      console.log('this.code',this.code)
      let data = {
        code: this.code
      };
      getTokenByCode(data)
        .then(res => {
          if (res === "超时") {
            Indicator.close();
            return;
          }
          console.log('res', res)
          const data = res.data;
          if (data && data.status === 1) {
            Indicator.close();
            this.$store.dispatch("setToken", data.data.access_token);
            this.$store.dispatch("setRefresh", true);
            var referrer = sessionStorage.getItem("referrer");
            if (referrer.indexOf("/RcDoctor") != -1) {
              window.location.href = "/RcDoctor";
            } else if (referrer.indexOf("/UntargetDoctor") != -1) {
              window.location.href = "/UntargetDoctor";
            } else if (referrer.indexOf("/AirplaneHotelDoctor") != -1) {
              window.location.href = "/AirplaneHotelDoctor";
            } else {
              window.location.href = "/doctor";
            }
          } else {
            Indicator.close();
          }
        })
        .catch(error => {
          Indicator.close();
        });
    },
    getHttpData(data) {
      var temp = "";
      for (var i in data) {
        if (data[i] != null && data[i] !== "null") {
          temp = temp + "&" + i + "=" + data[i];
        }
      }
      temp = temp.substr(1, temp.length - 1);
      return temp;
    }
  }
};
</script>

<style lang="less" scoped>
.mint-field {
  margin-top: 20px;
}
@ErrorColor: #000000;
.login {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // padding-top: 55%;
}
.loade_animate {
  width: 100%;
  height: 100%;
}
.loade_animate > span {
  display: block;
  width: 100%;
  height: 100%;
}
.login_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 3rem;
}
.login_title {
  text-align: left;
  width: 5.9rem;
  color: @ErrorColor;
  font-size: 0.5rem;
  font-weight: 500;
}
.login_header {
  margin-top: 0.9rem;
  color: #000;
  font-size: 0.64rem;
  font-weight: 800;
}
.login_img {
  margin: 3rem 0 1.81rem;
}
.userInfo {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding-top: 55%;
}
.userInfo .mint-cell {
  border: 1px solid #1b4298 !important;
  border-radius: 10px;
}
.userInfo .mint-button {
  margin-top: 30px;
}
</style>
