<template>
  <div class="SSJG">
    <div v-for="(item,index) in doctor" :key="index">
      <hospitalCard :data="item" :is-show="item.isOpen"></hospitalCard>
    </div>
    <filterDataNull v-if="filterData">
      <p class="titel">未找到符合条件的数据 !</p>
    </filterDataNull>
    <server-error v-if="error">
      <mt-button class="btn-param-color" @click="reload">重新加载</mt-button>
    </server-error>
    <net-work-error v-if="netError">
      <mt-button class="btn-param-color" @click="reload">重新加载</mt-button>
    </net-work-error>
    <back-top :package=" 'animate' "></back-top>
  </div>
</template>
<script>
import { Indicator } from 'mint-ui'
import hospitalCard from '@/components/common/hospitalCard'
import { getHcpList } from '@/api/api.js'
import { getUntargetHcpList } from '@/api/api.js'
import { getUnLikeHcpList } from '@/api/api.js'
import ServerError from '@/components/error/error'
import NetWorkError from '@/components/error/netWorkError'
import filterDataNull from '@/components/error/filterDataNull'
import BackTop from '@/components/backTop/index'
export default {
  name: 'SSJG',
  components: {
    hospitalCard,
    ServerError,
    BackTop,
    NetWorkError,
    filterDataNull,
  },
  data() {
    return {
      doctor: [],
      newDoctor: [],
      netWork: false,
      serviceErr: false,
      filterData: false,
      doctorList: [],
    }
  },
  computed: {
    netError() {
      return this.netWork
    },
    error() {
      return this.serviceErr
    },
    isAdd() {
      return this.$store.getters.mdmCode
    },
    showReloadSearch() {
      return this.$store.getters.reloadList
    },
  },
  watch: {
    isAdd(val) {},
    showReloadSearch() {
      this.$store.dispatch('setReloadList', false)
      this.reloadSearch()
    },
  },
  destroyed() {
    Indicator.close()
  },
  created() {
    this.searchResult()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    reloadSearch() {
      this.searchResult()
    },
    // 分组
    grouping(arr, prop) {
      var map = {}
      var dest = []
      // 对数据进行分组
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i]
        if (!map[ai[prop]]) {
          dest.push({
            HospitalName: ai[prop],
            isOpen: false,
            doctors: [ai],
          })
          map[ai[prop]] = ai
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j]
            if (dj.HospitalName === ai[prop]) {
              dj.doctors.push(ai)
              break
            }
          }
        }
      }
      return dest
    },
    initList() {
      this.nullData = false
      this.filterData = false
      this.doctor = []
    },
    reload() {
      this.searchResult()
    },
    searchResult() {
      this.$store.dispatch('setMdmCode', false)
      const keyWorld = localStorage.getItem('keyWorld')
      if (!keyWorld.trim()) {
        return
      }
      Indicator.open({
        text: '数据加载中...',
        spinnerType: 'fading-circle',
      })
      const data = {
        name: ' ',
        offset: -1,
        limit: 20,
        timestamp: Date.now(),
      }
      data.name = keyWorld.trim()
      if (
        !this.$store.getters.showTargetSwitch &&
        this.$store.getters.searchUntargetList &&
        this.$store.getters.showUntargetDoctor
      ) {
        // 加载搜索添加非目标客户列表
        this.initList()
        getUnLikeHcpList(data)
          .then((res) => {
            if (res === '超时') {
              Indicator.close()
              this.netWork = true
              this.filterData = false
              this.serviceErr = false
              return
            }
            if (res === 'token失效') {
              this.serviceErr = true
              this.filterData = false
              this.netWork = false
              Indicator.close()
              this.$messagebox
                .alert(
                  `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                  '提示'
                )
                .then((action) => {
                  window.location.href = '/login'
                })
              return
            }
            const data = res.data
            if (data && data.status === 1) {
              this.netWork = false
              this.serviceErr = false
              // this.grouping(data.data)
              const list = data.data
              this.doctor = this.grouping(list, 'workplaceName')
              this.doctor.forEach((item) => {
                item.isOpen = true
              })
              Indicator.close()
              if (this.doctor.length === 0) {
                this.filterData = true
              } else {
                this.filterData = false
              }
            } else {
              this.serviceErr = true
              this.filterData = false
              this.netWork = false
              Indicator.close()
            }
          })
          .catch((error) => {
            this.serviceErr = true
            this.filterData = false
            this.netWork = false
            Indicator.close()
          })
      } else if (this.$store.getters.showUntargetDoctor) {
        // 加载非目标客户列表
        this.initList()
        getUntargetHcpList(data)
          .then((res) => {
            if (res === '超时') {
              Indicator.close()
              this.netWork = true
              this.filterData = false
              this.serviceErr = false
              return
            }
            if (res === 'token失效') {
              this.serviceErr = true
              this.filterData = false
              this.netWork = false
              Indicator.close()
              this.$messagebox
                .alert(
                  `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                  '提示'
                )
                .then((action) => {
                  window.location.href = '/login'
                })
              return
            }
            const data = res.data
            if (data && data.status === 1) {
              this.netWork = false
              this.serviceErr = false
              const list = data.data
              for (const i in list) {
                if (!list[i].isBind) {
                  list[i].isBind = 0
                }
                if (!list[i].isFollow) {
                  list[i].isFollow = 0
                }
              }
              this.doctor = this.grouping(list, 'workplaceName')
              this.doctor.forEach((item) => {
                item.isOpen = true
              })
              Indicator.close()
              if (this.doctor.length === 0) {
                this.filterData = true
              } else {
                this.filterData = false
              }
            } else {
              this.serviceErr = true
              this.filterData = false
              this.netWork = false
              Indicator.close()
            }
          })
          .catch((error) => {
            this.serviceErr = true
            this.filterData = false
            this.netWork = false
            Indicator.close()
          })
      } else if (!this.$store.getters.showUntargetDoctor) {
        // 加载目标客户列表
        this.initList()
        getHcpList(data)
          .then((res) => {
            if (res === '超时') {
              Indicator.close()
              this.netWork = true
              this.filterData = false
              this.serviceErr = false
              return
            }
            if (res === 'token失效') {
              this.serviceErr = true
              this.filterData = false
              this.netWork = false
              Indicator.close()
              this.$messagebox
                .alert(
                  `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                  '提示'
                )
                .then((action) => {
                  window.location.href = '/login'
                })
              return
            }
            const data = res.data
            if (data && data.status === 1) {
              this.netWork = false
              this.serviceErr = false
              const list = data.data
              for (const i in list) {
                if (!list[i].isBind) {
                  list[i].isBind = 0
                }
                if (!list[i].isFollow) {
                  list[i].isFollow = 0
                }
              }
              this.doctor = this.grouping(list, 'workplaceName')
              this.doctor.forEach((item) => {
                item.isOpen = true
              })
              Indicator.close()
              if (this.doctor.length === 0) {
                this.filterData = true
              } else {
                this.filterData = false
              }
            } else {
              this.serviceErr = true
              this.filterData = false
              this.netWork = false
              Indicator.close()
            }
          })
          .catch((error) => {
            this.serviceErr = true
            this.filterData = false
            this.netWork = false
            Indicator.close()
          })
      }
      Indicator.close()
    },
  },
}
</script>
<style lang="less">
.SSJG {
  margin-top: 0.4rem;
}

.searchResult p {
  // display:inline-block;
  color: #333;
  font-size: 0.32rem;
  // margin:0;
  font-weight: 600;
  padding: 0.32rem 0 0.32rem 5px;
  margin-left: 0.32rem;
}
.searchResult {
  background: #fff;
  width: 9rem;
  min-height: 32/37.5rem;
  margin: 0 auto 8/37.5rem;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
}
</style>
