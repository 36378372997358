<template>
  <div class="main">
    <div v-if="$store.getters.showTargetSwitch" class="historyBox">
      <div class="title">
        <span>搜索历史</span>
      </div>
      <div class="history_list">
        <span
          v-for="(item,index) in list"
          :key="index"
          class="search_history"
          @click="LSclick(item)"
        >{{ item }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RCSSLS',
  props: {
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    path() {
      return this.$store.getters.path
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.$store.dispatch('setGoBack', true)
    this.getLocalStorge(this.$store.getters.showUntargetDoctor)
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    getLocalStorge(showUntargetDoctor) {
      this.list = []
      if (!showUntargetDoctor) {
        this.list = JSON.parse(localStorage.getItem('doctorSSLS') || '[]')
      } else if (showUntargetDoctor) {
        this.list = JSON.parse(localStorage.getItem('RcshareSSLS') || '[]')
      }
    },
    LSclick(item) {
      this.$emit('RCSSLSClick', item)
      localStorage.setItem('keyWorld', item)
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  margin: 0.64rem 0;
}
.title span {
  font-size: 20/37.5rem;
  margin: 0 0 0 0.64rem;
}

.history_list {
  width: 9rem;
  margin: 10/37.5rem 0 0 24/37.5rem;
  font-size: 18/37.5rem;
  color: #999999;
}

.history_list span {
  margin: 0 0.69rem 0.64rem 0;
  display: inline-block;
}
.search_history {
  font-size: 0.4rem;
  background: rgba(27,66,152,0.2);
  border-radius: 10px 10px 10px 10px;
  padding: 2px 8px 2px 8px;
  font-weight: normal;
  box-sizing: border-box;
}
</style>
