import router from "./router";
import store from "@/store";
import { getCookie } from "@/utils/cookie.js";
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  //store存储token
  if (!store.getters.token) {
    if (localStorage.getItem("messageStore")) {
      if (JSON.parse(localStorage.getItem("messageStore")).user.token) {
        store.dispatch(
          "setToken",
          JSON.parse(localStorage.getItem("messageStore")).user.token
        );
      }
    }
  }
  //判断路由是否需要token
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 检测需要验证token的路由
    if (!getCookie("token") || getCookie("token") === "null") {
      // token存在条件
      if (to.path !== "/") {
        sessionStorage.setItem("referrer", to.path); //储存来源路由
      }
      next({
        path: "/login" // 验证失败要跳转的页面
      });
      return;
    } else {
      if (to.path !== "/") {
        sessionStorage.setItem("referrer", to.path); //储存来源路由
      }
      next();
      return;
    }
  } else {
    next(); // 确保一定要调用 next()
  }
});
router.afterEach(() => {
  // next()
});
