<template lang="html">
  <div class="main">
    <div v-if="!netError" class="qrCode_main">
      <div ref="wrapper" class="main-body">
          <div class=" " v-show="!isShowLoadTop">
            <doctorCard :data="cardData" :qr-code="false"> </doctorCard>
            <el-collapse v-if="qrCodeError && cardData.validateState == 'validated'" v-model="activeName">
              <el-collapse-item name="1" style="">
                <template slot="title">
                  <img src="@/assets/detail1.png" style="">详细信息</img>
                </template>
                <div class="collapse-content">
                  <div class="detail"><span>类型：</span>{{cardData.customerType}}</div>
                  <div class="detail"><span>MDM：</span>{{cardData.mdmCode}}</div>
                  <div class="detail"><span>工作地点：</span>{{cardData.workplaceName}}</div>
                  <div class="detail"><span>最近绑定时间：</span>{{cardData.bindTime}}</div>
                  <div class="detail"><span>最近绑定方式：</span>{{cardData.bindSource =='scan'?'扫码绑定':'注册'}}</div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <!-- 二维码生成成功 -->
            <div
              v-if="qrCodeError && cardData.validateState != 'validated'"
              class="qrCode_img"
            >
              <div class="img">
                <img v-if="qrImg" :src="qrImg" alt=" " />
                <div v-else></div>
              </div>
              <div v-if="qrImg" class="title">
                <span>该二维码有效期到 {{ expireSeconds }}</span>
                <span>我已仔细阅读屏幕上方对应的客户信息，并确认无误。</span>
              </div>
            </div>
            <!-- 二维码生成失败 -->
            <div v-if="!qrCodeError" class="qrCode_img">
              <div class="img" @click="regenerateQRCode">
                <img src="@/assets/createQrError.png" alt=" " />
              </div>
              <div class="titleError">
                <span>二维码生成失败请刷新重试</span>
              </div>
            </div>
          </div>
        <mt-loadmore
          v-show="isShowLoadTop&&!netError"
          ref="loadmore"
          :top-method="getData"
        >
          <div class=" ">
            <doctorCard :data="cardData" :qr-code="false"> </doctorCard>
            <el-collapse v-if="qrCodeError && cardData.validateState == 'validated'" v-model="activeName">
              <el-collapse-item name="1" style="">
                <template slot="title">
                  <img src="@/assets/detail1.png" style="">详细信息</img>
                </template>
                <div class="collapse-content">
                  <div class="detail"><span>类型：</span>{{cardData.customerType}}</div>
                  <div class="detail"><span>MDM：</span>{{cardData.mdmCode}}</div>
                  <div class="detail"><span>工作地点：</span>{{cardData.workplaceName}}</div>
                  <div class="detail"><span>最近绑定时间：</span>{{cardData.bindTime}}</div>
                  <div class="detail"><span>最近绑定方式：</span>{{cardData.bindSource =='scan'?'扫码绑定':'注册'}}</div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <!-- 二维码生成成功 -->
            <div
              v-if="qrCodeError && cardData.validateState != 'validated'"
              class="qrCode_img"
            >
              <div class="img">
                <img v-if="qrImg" :src="qrImg" alt=" " />
                <div v-else></div>
              </div>
              <div v-if="qrImg" class="title">
                <span>该二维码有效期到 {{ expireSeconds }}</span>
                <span>我已仔细阅读屏幕上方对应的客户信息，并确认无误。</span>
              </div>
            </div>
            <!-- 二维码生成失败 -->
            <div v-if="!qrCodeError" class="qrCode_img">
              <div class="img" @click="regenerateQRCode">
                <img src="@/assets/createQrError.png" alt=" " />
              </div>
              <div class="titleError">
                <span>二维码生成失败请刷新重试</span>
              </div>
            </div>
          </div>
        </mt-loadmore>
      </div>
    </div>
    <net-work-error v-if="netError">
      <mt-button class="btn-param-color" @click="reload">重新加载</mt-button>
    </net-work-error>
  </div>
</template>
<script>
import NetWorkError from '@/components/error/netWorkError'
import doctorCard from '@/components/common/doctorCard'
import { Indicator } from 'mint-ui'
import { getUntargetHcpList,getRcHcpList, createQRCode, getHcpList, getHcpDetail} from '@/api/api.js'
import moment from 'moment'
export default {
  components: {
    doctorCard,
    NetWorkError,
  },
  data() {
    return {
      qrCodeError: true,
      cardData: {},
      title: '返回',
      qrImg: '',
      expireSeconds: ' ',
      netWork: false,
      showLoadTop: true,
      activeName:['1','2'],
      ready:false
      // firstData:{},
    }
  },
  computed: {
    isShowLoadTop() {
      return this.showLoadTop
    },
    netError() {
      return this.netWork
    },
  },
  created() {
    this.setScrollTop(0)
    this.$store.dispatch('setMdmCode', false)
    this.$store.dispatch('setShowAddUntargetList', false)
    this.$store.dispatch('setSearchStatus', false)
  },
  mounted() {
    const data = sessionStorage.getItem('doctorData')
    this.cardData = JSON.parse(data)
    if(this.cardData.validateState != 'validated'){
      this.regenerateQRCode()
    }else{
      Indicator.open({
        text: '数据加载中...',
        spinnerType: 'fading-circle',
      })
      this.hcpDetail(this.cardData.id)
    }
    window.addEventListener('scroll', this.handleScroll, false)
  },
  beforeDestroy() {},
  destroyed() {
    Indicator.close()
    this.$store.dispatch('setSearchStatus', true)
    this.$store.dispatch('setShowTargetSwitch', true)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    hcpDetail(id){
      const _this = this
      getHcpDetail(id)
        .then(res=>{
          if (res === '超时' || res === '504') {
            Indicator.close()
            this.netWork = true
            return
          }
          if (res === 'token失效') {
            _this.$refs.loadmore.onTopLoaded()
            Indicator.close()
            this.$messagebox
              .alert(
                `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                '提示'
              )
              .then((action) => {
                window.location.href = '/login'
              })
            return
          }
          if (res.data.data) {
            Indicator.close()
            let data = res.data.data
            data.bindTime = moment(data.bindTime).format('YYYY-MM-DD HH:mm:ss');
            _this.cardData = data
            _this.netWork = false
            if (
              !res.data.data.validateState ||
              res.data.data.validateState !== 'validated'
            ) {
              _this.cardData.validateState = 0
            } else {
              _this.cardData.validateState = res.data.data.validateState
            }
            if (!res.data.data.isFollow) {
              _this.cardData.isFollow = 0
            } else {
              _this.cardData.isFollow = res.data.data.isFollow
            }
            if (res.data.data.nickName) {
              _this.cardData.nickName = res.data.data.nickName
            }
            if (res.data.data.wechatAvatar) {
              _this.cardData.wechatAvatar = res.data.data.wechatAvatar
            } else {
              _this.cardData.wechatAvatar = ''
            }
          }

        })
        .catch((error) => {
          console.log(error)
          Indicator.close()
          this.netWork = true
          return
        })
    },
    getScrollTop() {
      const scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      return scrollTop
    },
    handleScroll() {
      this.scrollTop = this.getScrollTop()
      if (this.scrollTop <= 1) {
        this.showLoadTop = true
      } else {
        this.showLoadTop = false
      }
    },
    setScrollTop(scroll_top) {
      document.documentElement.scrollTop = scroll_top
      window.pageYOffset = scroll_top
      document.body.scrollTop = scroll_top
    },
    //返回上一步
    back() {
      const path = this.$route.query.path
      if (path === 'Nav') {
        this.$store.dispatch('setShowTargetSwitch', true)
        this.$store.dispatch('setSearchStatus', true)
        this.$router.push({ path: '/RcDoctor' })
      } else if (path === 'SSJG') {
        this.$store.dispatch('setShowTargetSwitch', true)
        this.$store.dispatch('setSearchStatus', true)
        this.$router.push({ path: '/RcDoctor/RCSSJG' })
      }
    },
    reload() {
      this.regenerateQRCode()
    },
    getData() {
      const _this = this
      const data = {
        name: this.cardData.name,
        offset: -1,
      }
      if (this.$store.getters.showUntargetDoctor) {
        getRcHcpList(data)
          .then(res=>{
            _this.$refs.loadmore.onTopLoaded()
              if (res === '超时' || res === '504') {
                Indicator.close()
                this.netWork = true
                return
              }
              if (res === 'token失效') {
                _this.$refs.loadmore.onTopLoaded()
                Indicator.close()
                this.$messagebox
                  .alert(
                    `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                    '提示'
                  )
                  .then((action) => {
                    window.location.href = '/login'
                  })
                return
              }
              if(res.data.data){
                for(let i in res.data.data){
                  if(res.data.data[i].mdmCode == this.cardData.mdmCode&&res.data.data[i].validateState == 'validated'){
                    this.hcpDetail(res.data.data[i].id)
                  }
                }
              }

          })
          .catch(error=>{
            console.log(error)
            Indicator.close()
            this.netWork = true
            return
          })
      }else{
        getHcpList(data)
          .then(res=>{
            _this.$refs.loadmore.onTopLoaded()
              if (res === '超时' || res === '504') {
                Indicator.close()
                this.netWork = true
                return
              }
              if (res === 'token失效') {
                _this.$refs.loadmore.onTopLoaded()
                Indicator.close()
                this.$messagebox
                  .alert(
                    `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                    '提示'
                  )
                  .then((action) => {
                    window.location.href = '/login'
                  })
                return
              }
              if(res.data.data){
                for(let i in res.data.data){
                  if(res.data.data[i].mdmCode == this.cardData.mdmCode&&res.data.data[i].validateState == 'validated'){
                    this.hcpDetail(res.data.data[i].id)
                  }
                }
              }

          })
          .catch(error=>{
            console.log(error)
            Indicator.close()
            this.netWork = true
            return
          })
      }

    },
    // 生成二维码
    regenerateQRCode() {
      Indicator.open({
        text: '二维码生成中...',
        spinnerType: 'fading-circle',
      })
      // 请求参数
      const data = {
        expireSeconds:
          moment().add(6, 'day').endOf('day').unix() - moment().unix(),
        mdmCode: null,
      }
      data.mdmCode = this.cardData.mdmCode
      createQRCode(data)
        .then((res) => {
          if (res === '超时' || res === '504') {
            this.qrCodeError = false
            Indicator.close()
            this.netWork = true
            return
          }
          if (res === 'token失效') {
            this.qrCodeError = false
            Indicator.close()
            this.$messagebox
              .alert(
                `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                '提示'
              )
              .then((action) => {
                window.location.href = '/login'
              })
            return
          }
          const data = res.data
          if (data && data.status === 1) {
            this.netWork = false
            this.qrCodeError = true
            this.qrImg = data.data.qrcodeUrl
            this.expireSeconds = moment(
              moment().add(6, 'day').endOf('day').valueOf()
            ).format('YYYY-MM-DD')
            setTimeout(() => {
              Indicator.close()
            }, 1000)
          } else {
            this.qrCodeError = false
            Indicator.close()
          }
        })
        .catch((error) => {
          this.netWork = false
          Indicator.close()
          this.qrCodeError = false
        })
    },
  },
}
</script>

<style lang="css" scoped>
.main-body {
  padding-top: 5vh;
  /* 加上这个才会有当数据充满整个屏幕，可以进行上拉加载更多的操作 */
  overflow: scroll;
}
.mint-loadmore {
  min-height: 95vh;
}
.qrCode_main {
  width: 90%;
  margin: 0 auto;
}
.qrCode_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.4rem;
  width: 180/37.5rem;
}
.qrCode_img .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.187rem;
}
.titleError {
  margin-top: 0.187rem;
  font-size: 0.43rem;
  color: #e82626;
}
.qrCode_img .title :first-child {
  color: #333333;
  font-weight: 500;
  font-size: 0.25rem;
  /* color:#333;
  font-weight: 500;
  font-size: .34rem; */
  margin-bottom: 0.08rem;
}
.qrCode_img .title :last-child {
  margin-top: 0.5333rem;
  color: #333333;
  font-weight: 500;
  font-size: 0.3rem;
  -webkit-transform: scale(0.83);
}
.qrCode_img .img {
  width: 5.33rem;
  height: 5.33rem;
  background-color: #fff;
}
.qrCode_img .img img {
  width: 5.33rem;
  height: 5.33rem;
}
.hospitalCard {
  padding-top: 0.42rem;
  width: 100%;
  min-height: 32/37.5rem;
  margin: 0 auto 8/37.5rem;
  border-radius: 4px;
}
.back {
  width: 0.42666667rem;
  height: 0.21333333rem;
  transform: rotatez(90deg);
  margin: auto 0;
}
.collapse-content{
  min-height: 0.85333333rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}
.collapse-content .null-data{
  margin-bottom:0.0533rem;
  text-align:center;
}
.collapse-content .interesting{
  margin-bottom:0.08rem;
}
.collapse-content .interesting>div>span{
  border:0.0267rem solid #1B4298;
  color:rgba(108, 83, 144, 1);
  background:rgba(0, 133, 124,0.06);
  padding:0px 0.08rem 0px 0.08rem;
  margin-right:0.2667rem;
  display:inline-block;
  margin-bottom:0.1867rem;
  border-radius:0.1333rem;
}
.el-collapse-item img{
  width:0.58666rem;
  padding-right: 0.1rem;
  padding-left: 0.1rem;
}
.collapse-content div{
  width: 100%;
  margin-bottom:0px;
  font-size:0.32rem;
}
.collapse-content .detail{
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.collapse-content>div>span{
  display: inline-block;
  width: 2.6rem;
}
</style>
