<template>
  <div class="main">
    <div class="login col-b2">
      <div v-if="$store.getters.showTargetSwitch" class="switchBtn">
        <mt-button
          :class="[
            $store.getters.showUntargetDoctor
              ? 'mint-button--default'
              : 'mintbuttonclick',
          ]"
          @click="targetDoctor"
          >目标客户</mt-button
        >
        <mt-button
          :class="[
            $store.getters.showUntargetDoctor
              ? 'mintbuttonclick'
              : 'mint-button--default',
          ]"
          @click="unTargetDoctor"
          >非目标客户</mt-button
        >
      </div>
      <!-- 搜索框样式 -->
      <div
        v-if="inputStatus || $store.getters.searchUntargetList"
        class="input-group"
      >
        <div class="search_Input">
          <i class="mintui mintui-search"></i>
          <form action="javascript:return true" style="width: 100%">
            <mt-field
              id="main_search2"
              v-model="value"
              type="search"
              placeholder="请输入客户姓名"
              @keyup.enter.native.stop="search"
              @input="input"
              @click.native="focus"
            ></mt-field>
          </form>
          <!-- <div
            v-if="$store.getters.showUntargetDoctor&&$store.getters.showTargetSwitch&&!$store.getters.go"
            class="addUntargetBtn"
          >
            <img
              src="@/assets/addBtn.png"
              class="addUntargetBtnImg"
              alt="添加至我的列表"
              @click="addUntargetDoctor"
            />
          </div> -->
        </div>
      </div>
      <!-- 嵌套路由页面显示 -->
      <router-view
        v-if="$route.meta.keepAlive"
        @UntargetSSLSClick="UntargetSSLSClick"
      ></router-view>
      <router-view
        v-if="!$route.meta.keepAlive"
        @UntargetSSLSClick="UntargetSSLSClick"
      ></router-view>
      <!-- 搜索框样式结束 -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'indexPage',
  data() {
    return {
      loading: 'el-icon-search',
      value: '',
      gobackStatus: null,
      showTargetSwitch: true,
      showUntargetDoctor: true,
    }
  },
  computed: {
    inputStatus() {
      return this.$store.getters.searchStatus
    },
    goback() {
      return this.$store.getters.go
    },
    path() {
      return this.$store.getters.path
    },
    showTargetDoctorSwitchBtn() {
      return this.$store.getters.showTargetSwitch
    },
  },
  watch: {
    goback(newVal, oldVal) {
      this.value = ''
      this.gobackStatus = newVal
    },
    showTargetDoctorSwitchBtn(val) {
      this.showTargetSwitch = val
    },
  },
  created() {
    this.value = ''
    this.unTargetDoctor()
    this.$store.dispatch('setShowUntargetDoctor', true)
  },
  mounted() {},
  beforeDestroy() {
    this.$store.dispatch('setShowUntargetDoctor', false)
  },
  methods: {
    targetDoctor() {
      this.value = ''
      this.$router.push({ path: '/doctor/Nav' })
      this.$store.dispatch('setSearchStatus', true)
      this.$store.dispatch('setShowAddUntargetList', false)
      this.$store.dispatch('setShowUntargetDoctor', false)
      this.$store.dispatch('setGoBack', false)
      this.showUntargetDoctor = this.$store.getters.showUntargetDoctor
    },
    unTargetDoctor() {
      this.value = ''
      this.$router.push({ path: '/UntargetDoctor/UntargetNav' })
      this.$store.dispatch('setSearchStatus', true)
      this.$store.dispatch('setShowAddUntargetList', true)
      this.$store.dispatch('setShowUntargetDoctor', true)
      this.$store.dispatch('setGoBack', false)
      this.showUntargetDoctor = this.$store.getters.showUntargetDoctor
    },
    UntargetSSLSClick(e) {
      this.value = e.trim()
      this.search()
    },
    search(e) {
      this.value = this.value.trim()
      var test2 = /\S/
      if (!test2.test(this.value)) {
        this.value = ''
        return
      }
      if (!this.$store.getters.showTargetSwitch) {
        localStorage.setItem('keyWorld', this.value)
        this.$router.push({ name: 'UntargetSSJG' })
      } else {
        if (this.value) {
          localStorage.setItem('keyWorld', this.value)
          let temp = []
          if (!this.showUntargetDoctor) {
            temp = JSON.parse(
              localStorage.getItem('UntargetdoctorSSLS') || '[]'
            )
          } else if (this.showUntargetDoctor) {
            temp = JSON.parse(localStorage.getItem('UntargetshareSSLS') || '[]')
          }
          for (var i in temp) {
            if (this.value === temp[i]) {
              temp.splice(i, 1)
              temp.unshift(this.value)
              if (!this.showUntargetDoctor) {
                localStorage.setItem('UntargetdoctorSSLS', JSON.stringify(temp))
                this.$router.push({ name: 'UntargetSSJG' })
              } else if (this.showUntargetDoctor) {
                localStorage.setItem('UntargetshareSSLS', JSON.stringify(temp))
                this.$router.push({ name: 'UntargetSSJG' })
              }
              return false
            }
          }
          if (temp.length === 10) {
            temp.pop()
          }
          temp.unshift(this.value)
          if (!this.showUntargetDoctor) {
            localStorage.setItem('UntargetdoctorSSLS', JSON.stringify(temp))
            this.$router.push({ name: 'UntargetSSJG' })
          } else if (this.showUntargetDoctor) {
            localStorage.setItem('UntargetshareSSLS', JSON.stringify(temp))
            this.$router.push({ name: 'UntargetSSJG' })
          }
        }
      }
    },
    input(e) {
      if (e.trim() === '' && this.$store.getters.go) {
        this.focus()
      }
    },
    focus() {
      this.$router.push({ path: '/UntargetDoctor/UntargetSSLS' })
    },
    addUntargetDoctor() {
      // 跳转到添加至我的列表页面
      this.value = ''
      this.$store.dispatch('setShowTargetSwitch', false)
      this.$store.dispatch('setShowAddUntargetList', true)
      this.$store.dispatch('setSearchStatus', true)
      this.$router.push({ path: '/UntargetDoctor/UntargetSSLS' })
    },
    back() {
      this.$store.dispatch('setSearchStatus', true)
      this.$router.push({ path: '/UntargetDoctor/UntargetNav' })
      this.$store.dispatch('setShowTargetSwitch', true)
      this.$store.dispatch('setShowAddUntargetList', false)
      this.$store.dispatch('setMdmCode', false)
      this.$store.dispatch('setGoBack', false)
      this.value = ''
    },
  },
}
</script>

<style lang="less">
.input-group {
  .mintui-field-error {
    padding-right: 10px;
  }
  .mint-field-core {
    padding-left: 0.6rem;
    height: 100%;
    font-size: 0.4rem;
    color: #999999;
    background: rgba(228, 231, 237, 1);
  }
  .mint-cell-value {
    height: 0.95rem;
  }
  .mint-cell .mint-field .is-nolabel {
    height: 0.95rem !important;
  }
}
.input-group #main_search1 .mint-field-core {
  border-radius: 0.133333rem;
  border: 0.026666rem solid rgba(204, 204, 204, 1);
  background: #f5f7fa !important;
}
form {
  // width: 100%;
}
.mint-search-list {
  z-index: -5;
}

.mintui-search {
  font-size: 0.5rem !important;
  padding-left: 5px;
  /* width: 70%;
  margin: 0 auto; */
}
.mint-searchbar-core {
  padding-left: 9px;
  font-size: 0.48rem;
}
.mint-searchbar-inner,
.mint-searchbar-core,
.mint-searchbar {
  background: #e4e7ed !important;
  color: #999999;
}
.back {
  /* margin-right: 10px; */
}
.history {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.history img {
  width: 20px;
  height: 20px;
}
.history span {
  font-size: 10px;
  -webkit-transform: scale(0.83);
}
.mint-searchbar-inner {
  height: 25/37.5rem !important;
  padding: 4/37.5rem 0;
}
.mint-searchbar {
  padding: 0 !important;
}
.switchBtn {
  // position: relative;
  padding-top: 20px;
  text-align: center;
  z-index: 9999;
}
.switchBtn .mint-button {
  width: 170px;
  height: 32px;
  color: #333333;
}
.switchBtn .mint-button--default {
  background: rgba(224, 242, 241, 1);
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
}
.switchBtn .mintbuttonclick {
  background: rgba(255, 255, 255, 1);
  color: #1b4298;
}
#main_search1 {
  height: 0.95rem !important;
  // border-radius: 0.95rem;
  // background: #e4e7ed !important;
  margin-left: -0.53rem;
  flex: 1;
}

#main_search2 {
  height: 0.95rem !important;
  border-radius: 0.95rem;
  background: #e4e7ed !important;
  margin-left: -0.53rem;
  flex: 1;
}
.mint-search {
  margin-left: 0px;
}
.mint-cell,
.mint-navbar {
  background: #f5f7fa !important;
  text-align: left;
}
.mint-tab-item-label {
  font-size: 0.4rem !important;
}
.tab_hospital .mint-tab-item-label {
  padding-left: 0.5rem !important;
  text-align: left;
}
.tab_bind .mint-tab-item-label {
  padding-right: 0.5rem !important;
  text-align: right;
}
.mint-tab-item {
  padding: 0 !important;
}
.navBox {
  width: 10rem;
}
.navCell {
  border-bottom: 1px solid #e4e7ed;
}
.mint-cell-wrapper {
  max-height: 40px !important;
  background-image: none;
}
</style>
<style scoped lang="less">
.search_Input {
  // height: 36px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mintui-search {
  position: relative;
  top: 0px;
  left: 8px;
  z-index: 10;
  color: #909399;
  font-size: 20px;
}

.back {
  height: 10px;
  transform: rotatez(90deg);
  margin: 0 10px;
}
@screenWidth: 37.5;
.navBar {
  width: 9rem;
  margin: 0 auto;
  position: relative;
}
.navArrow {
  width: 0.32rem;
  height: 0.16rem;
  vertical-align: middle;
  position: relative;
  left: 0.3rem;
}
.main {
  width: 100%;
}

.login {
  z-index: 9999;
  height: 100%;
}
.login-btn2 {
  margin: auto;
  margin-top: 20px;
  z-index: 9999;
  width: 90%;
}
.el-button:focus,
.el-button:hover {
  border-color: #1b4298;
  background-color: #1b4298;
  color: white;
}
.el-button {
  border-color: #1b4298;
  background-color: #1b4298;
  color: white;
}
.input-group {
  // height: 0.95rem;
  // height: 5.24vh;
  width: 90%;
  margin: 0 auto;
  // margin-bottom:0.1rem;
  display: flex;
  padding: 16/37.5rem 0 6/37.5rem 0;
  align-items: center;
  justify-content: space-around;
}
.el-form-item {
  margin-bottom: 0;
}

.input {
  margin-bottom: 20px;
  background-color: white;
  padding-left: 20px;
  border-radius: 5px;
  padding-right: 10px;
  text-align: left;
  width: 100%;
  height: 48px;
  position: relative;
}
.el-form-item__content .input {
  height: 100%;
  padding-left: 0px;
  margin-bottom: 0px;
}
.input-front {
  float: left;
  margin-top: 13px;
  color: black;
}
.input-front2 {
  float: left;
  margin-top: 13px;
  color: #757575;
  margin-left: 10px;
  font-size: 16px;
}
.input-front3 {
  float: right;
  margin-top: 13px;
  color: black;
  margin-right: 10px;
  font-size: 16px;
}
.reg-img {
  width: 24px;
  margin-top: 11px;
  float: left;
}
.reg-img2 {
  width: 20px;
  margin-top: 13px;
  float: right;
}
.input-input {
  border: none;
  background-image: none;

  width: 80%;
  height: 48px;
  font-size: 16px;
  padding-left: 8px;
  outline: 0;
}
.reg-btn {
  background-color: black;
  border: none;
  width: 100%;
}
.log-btn {
  float: right;
  margin-top: 5px;
  height: 40px;
  color: black;
  text-decoration: underline;
}
.reg-btn-ver {
  position: absolute;
  top: 11px;
  right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.navCellBox {
  width: 10rem;
  position: absolute;
  z-index: 2;
}
.addUntargetBtn {
  // margin-top: 16px;
  // display: none;
  margin-left: 0.2rem;
  line-height: 100%;
  text-align: center;
  float: right;
}
.addUntargetBtn .addUntargetBtnImg {
  width: 0.96rem;
}
.hospital .el-input__suffix {
  opacity: 0;
}
.el-drawer__header .el-input {
  font-size: 0.42666rem;
}
.el-drawer__header .el-input__icon {
  line-height: 1.06666rem !important;
}
.el-drawer__header .el-input__inner {
  height: 1.06666rem;
}
.el-drawer__header .el-button {
  // padding-top: 0px;
}
.el-radio {
  display: block;
  float: left;
  padding: 0.26666rem 0.533333rem;
  color: #4a4a4a;
  margin-right: 0px;
  width: 95%;
  box-sizing: border-box;
  border-top: 0.026666rem solid rgba(161, 161, 161, 0.2);
}
.clearfix:after {
  /*添加一个内容*/
  content: '';
  /*转换为一个块元素*/
  display: block;
  /*清除两侧的浮动*/
  clear: both;
}
.el-radio:nth-last-child(1) {
  border-bottom: 0.026666rem solid rgba(161, 161, 161, 0.2);
}
.el-radio__label {
  padding-left: 0px;
  display: inline-block !important;
}
.el-radio__label:after {
  /*添加一个内容*/
  content: '';
  /*转换为一个块元素*/
  display: block;
  /*清除两侧的浮动*/
  clear: both;
}
.el-radio__input {
  display: none;
}
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
input,
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
  margin: 0px;
  padding: 0px;
  outline: none;
}
.el-button--small,
.el-button--small.is-round {
  padding-bottom: 0px;
}
.location {
  color: #7a7a7a;
  font-size: 0.42666rem;
}
.hospitalLocation {
  color: #4a4a4a;
  white-space: normal;
  margin-bottom: 0.26666rem;
  font-size: 0.42666rem;
  line-height: 0.533333rem;
  font-weight: bold;
  letter-spacing: 0.024rem;
  word-break: break-all;
}
.mint-loadmore-bottom,
.mint-loadmore-top {
  line-height: 1.33333rem;
}
.mint-loadmore-bottom,
.mint-loadmore-top {
  font-size: 0.37333333rem;
}
.mint-loadmore-bottom,
.mint-loadmore-top {
  height: 1.3333rem;
}
.el-input .el-input__clear {
  font-size: 0.37333rem;
}
.el-button--small,
.el-button--small.is-round {
  padding: 0.24rem 0.4rem;
}
.el-button--mini,
.el-button--small {
  font-size: 0.32rem;
  border-radius: 0.08rem;
}
.el-input--prefix .el-input__inner {
  padding-left: 0.8rem;
}
.el-input--suffix .el-input__inner {
  padding-right: 0.8rem;
}
.el-radio-group {
  width: 100%;
}
input::-ms-clear {
  display: none;
}
input::-ms-reveal {
  display: none;
}
/*清除谷歌浏览器下的 search 叉号 */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
/*清除IE下的 search 叉号 */
input[type='search']::-ms-clear {
  display: none;
}
.workplace_cancel {
  // height: 0.6933333rem;
  // line-height: 0.6933333rem;
  vertical-align: 0px;
}
.wrapper_box {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* height: 70%; */
}
.el-input__prefix {
  left: 0.13333rem;
}
.el-input__suffix {
  right: 0.13333rem;
}
.el-drawer__header {
  padding: 0.5333rem 0.5333rem 0;
  margin-bottom: 0.853333rem;
}
.el-drawer__header .el-button {
  border: 0.02666rem solid #dcdfe6;
}
.el-input__inner {
  border-radius: 0.106666rem;
  border: 0.026666rem solid #dcdfe6;
  padding: 0 0.4rem;
}
.mint-loadmore-bottom {
  margin-bottom: -1.333rem;
}
.select_list:nth-child(1) {
  float: left;
  width: 47%;
}
.select_list:nth-child(2) {
  float: right;
  width: 47%;
}
#searchBtn {
  margin-top: 0.53rem;
  line-height: 0.6;
  width: 80%;
}
</style>
