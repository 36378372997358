<template>
  <div class="main">
    <div class="hospitalCard">
      <div class="hospital" @click="isShowNav(data)">
        <div :class="isshow ? 'hospitalOpen' : 'hospital-title' ">
          <p
            class="hospital_title"
          >{{ data.HospitalName }}</p>
          <div v-if="isshow" class="Arrow">
            <img src="@/assets/arrowFFF.png" alt />
          </div>
          <div v-if="!isshow" class="ArrowLeft">
            <img src="@/assets/arrowLight.png" alt />
          </div>
        </div>
      </div>
      <div v-for="(item,index) in data.doctors" :key="index">
        <div v-if="isshow" class>
          <doctorCard :data="item" :qr-code="true" @click.native="hcpDetail(item)"></doctorCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import doctorCard from './doctorCard'
export default {
  components: {
    doctorCard
  },
  props: {
    isShow: Boolean,
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    qrCode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    isshow() {
      return this.isShow
    }
  },
  watch: {},
  methods: {
    isShowNav(params) {
      params.isOpen ? (params.isOpen = false) : (params.isOpen = true)
    },
    hcpDetail(item){
      if(item.validateState == 'validated'&&(this.$store.getters.showUntargetDoctor&&this.$store.getters.showTargetSwitch||this.$route.path.indexOf('/doctor')!=-1)){
        this.$store.dispatch('setShowTargetSwitch', false)
        this.$store.dispatch('setSearchStatus', false)
        sessionStorage.setItem('doctorData', JSON.stringify(item))
        if(this.$route.path.indexOf('/RcDoctor')!=-1){
          this.$router.push({
            name: 'createdRcQRCode',
            query: { path: this.$route.name },
          })
        }else if(this.$route.path.indexOf('/UntargetDoctor')!=-1){
          this.$router.push({
            name: 'UntargetQRCode',
            query: { path: this.$route.name },
          })
        }else{
          this.$router.push({
            name: 'createdQRCode',
            query: { path: this.$route.name },
          })
        }
      }else{
        return false
      }

    }
  }
}
</script>
<style lang="less">
.hospitalOpen {
  background-color: #1B4298;
  padding: 0.32rem 0.32rem 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 1px 0px rgba(14, 14, 14, 0.1);
  color: #fff;
  border-radius: 5px 5px 0 0;
}
.hospital-title {
  padding: 0.32rem 0.32rem 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hospitalCard {
  background: #fff;
  width: 9rem;
  min-height: 32/37.5rem;
  margin: 0 auto 8/37.5rem;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
}
.hospital {
  width: 9rem;
  min-height: 32/37.5rem;
  font-size: 12/37.5rem;
  font-weight: bold;
  position: relative;
  margin: 0 0 10/37.5rem 0;
}
.hospital p {
  display: inline-block;
  margin: 0;
}
.hospital span {
  width: 9rem - 13/37.5rem;
  line-height: 32/37.5rem;
  min-height: 32/37.5rem;
  display: inline-block;
  padding: 5px 0 0 13/37.5rem;
  vertical-align: middle;
}
@height: 7/37.5rem;
.Arrow {
  width: 0.37333rem;
  height: 0.37333rem;
}
.ArrowLeft {
  width: 0.37333rem;
  height: 0.37333rem;
  transform: rotatez(-90deg);
}
.hospital_title{
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
