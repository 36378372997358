import axios from "axios";
import xss from "xss";
// import { Toast } from 'mint-ui'
import { fillterObj } from "@/utils/index";
import { delCookie, getCookie } from "@/utils/cookie.js";
import { errorCode } from "./errorCode";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_URL, //配置公共路径
  withCredentials: false,
  timeout: 60000 // request timeout
});
// 设置签名
const signUrl = process.env.VUE_APP_SIGN;
const bcrypt = require("bcryptjs");
function createStrSignStr(obj) {
  const sdic = Object.keys(obj).sort();
  let temp = "";
  for (const index in sdic) {
    if (sdic[index] !== "timestamp") {
      if (obj[sdic[index]] !== "") {
        if (typeof obj[sdic[index]] !== "object") {
          temp += `${sdic[index]}=${obj[sdic[index]]}&`;
        } else {
          temp += `${sdic[index]}=${JSON.stringify(obj[sdic[index]])}&`;
        }
      }
    }
  }
  return temp.substring(0, temp.length - 1);
}
function createSign(option, url, data) {
  if (data) {
    if (!Object.keys(data).length) {
      data.timestamp = null;
      data.sign = null;
      const signStr = option + "," + signUrl + url;
      const salt = bcrypt.genSaltSync(10);
      const hash = bcrypt.hashSync(signStr, salt);
      return hash;
    } else {
      data.timestamp = null;
      data.sign = null;
      const fliterUn = fillterObj(data);
      const str = createStrSignStr(fliterUn);
      const signStr = option + "," + signUrl + url + "," + str;
      const salt = bcrypt.genSaltSync(10);
      const hash = bcrypt.hashSync(signStr, salt);
      return hash;
    }
  } else {
    const signStr = option + "," + signUrl + url;
    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(signStr, salt);
    return hash;
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    //签名
    const request = config.method;

    const url = config.url;
    let data = {};
    if (getCookie("token")) {
      config.headers["Authorization"] = "Bearer " + getCookie("token");
    }
    if (request === "get") {
      data = JSON.parse(xss(JSON.stringify(config.params)));
      const sign = createSign("get", url, data);
      data["sign"] = sign;
      data["timestamp"] = parseInt(Date.now() / 1000);
      config.params = data;
    } else if (request === "post") {
      data = JSON.parse(xss(JSON.stringify(config.params)));

      const sign = createSign("post", url, data);
      const filterData = fillterObj(data);
      const dataObj = {
        sign: "",
        data: {},
        timestamp: parseInt(Date.now() / 1000)
      };
      dataObj["sign"] = sign;
      dataObj.data = filterData;
      config.data = dataObj;
    } else if (request === "delete") {
      data = JSON.parse(xss(JSON.stringify(config.params)));
      const sign = createSign("delete", url);
      const filterData = fillterObj(data);
      const dataObj = {
        sign: "",
        timestamp: parseInt(Date.now() / 1000)
      };
      dataObj["sign"] = sign;
      config.data = { data: filterData };
      config.params = dataObj;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// respone interceptor
service.interceptors.response.use(
  response => {
    if (response.data.data) {
      response.data.data = JSON.parse(xss(JSON.stringify(response.data.data)));
    }
    return response;
  },
  error => {
    const code = error.toString().replace(/[^0-9]/gi, "");
    const message = error.response.data;
    //请求超时错误处理
    if (
      (error.code === "ECONNABORTED" &&
        error.message.indexOf("timeout") !== -1) ||
      error.message.indexOf("Network Error") !== -1 ||
      code === "504"
    ) {
      return "超时";
      //没有权限
    } else if (code === "401") {
      delCookie("token");
      window.location.href = '/login'
      if (message.code === 90003) {
        return errorCode.code[90003];
      } else {
        return error;
      }
    }
  }
);

export default service;
