import xss from 'xss'

export function fillterObj(obj) {
  let paramsData = JSON.parse(xss(JSON.stringify(obj)))
  const objFilter = {}
  for (var i in paramsData) {
    if (paramsData[i]||paramsData[i] == 0) {
      objFilter[i] = paramsData[i]
    }
  }
  return objFilter
}
