import { render, staticRenderFns } from "./RcMain.vue?vue&type=template&id=f4b66cba&scoped=true"
import script from "./RcMain.vue?vue&type=script&lang=js"
export * from "./RcMain.vue?vue&type=script&lang=js"
import style0 from "./RcMain.vue?vue&type=style&index=0&id=f4b66cba&prod&lang=less"
import style1 from "./RcMain.vue?vue&type=style&index=1&id=f4b66cba&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4b66cba",
  null
  
)

export default component.exports