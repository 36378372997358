import { setCookie, getCookie } from "@/utils/cookie.js";

const user = {
  state: {
    token: getCookie("token"),
    loginStatus: false,
    NetworkError: false,
    path: localStorage.getItem("goPath"),
    historyImg: false,
    goback: false,
    showUntargetDoctor: false,
    searchStatus: true,
    showTargetSwitch: true,
    searchUntargetList: false,
    reloadList: false,
    refresh: false,
    mdmCode: []
  },
  mutations: {
    SET_REFRESH: (state, status) => {
      state.refresh = status;
    },
    SET_SEARCHSTATUS: (state, status) => {
      state.searchStatus = status;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_LOGINSTATUS: (state, loginStatus) => {
      state.loginStatus = loginStatus;
    },
    SET_NETWORKERROR: (state, networkStatus) => {
      state.NetworkError = networkStatus;
    },
    SET_PATH: (state, path) => {
      state.path = path;
    },
    SET_HISTORY_IMG: (state, status) => {
      state.historyImg = status;
    },
    SET_GOBACK: (state, status) => {
      state.goback = status;
    },
    SET_SHOW_UNTARGETDOCTOR: (state, status) => {
      state.showUntargetDoctor = status;
    },
    SET_SHOW_TARGETSWITCH: (state, status) => {
      state.showTargetSwitch = status;
    },
    SET_SHOW_ADDUNTARGETLIST: (state, status) => {
      state.searchUntargetList = status;
    },
    SET_RELOAD_LIST: (state, status) => {
      state.reloadList = status;
    },
    SET_MDM_CODE: (state, status) => {
      if (status) {
        state.mdmCode.push(status);
      } else {
        state.mdmCode = [];
      }
    },
    DEL_MDM_CODE: (state, status) => {
      if (state.mdmCode.indexOf(status) !== -1) {
        state.mdmCode.splice(state.mdmCode.indexOf(status), 1);
      }
    }
  },
  actions: {
    setRefresh({ commit }, status) {
      commit("SET_REFRESH", status);
    },
    setSearchStatus({ commit }, status) {
      commit("SET_SEARCHSTATUS", status);
    },
    setToken({ commit }, token) {
      commit("SET_TOKEN", token);
      setCookie("token", token);
    },
    setLoginStatus({ commit }, loginStatus) {
      commit("SET_LOGINSTATUS", loginStatus);
    },
    setNetWorkError({ commit }, networkStatus) {
      commit("SET_NETWORKERROR", networkStatus);
    },
    setPath({ commit }, path) {
      localStorage.setItem("goPath", path);
      commit("SET_PATH", path);
    },
    changeHistoryImg({ commit }, status) {
      commit("SET_HISTORY_IMG", status);
    },
    setGoBack({ commit }, status) {
      commit("SET_GOBACK", status);
    },
    setShowUntargetDoctor({ commit }, status) {
      commit("SET_SHOW_UNTARGETDOCTOR", status);
    },
    setShowTargetSwitch({ commit }, status) {
      commit("SET_SHOW_TARGETSWITCH", status);
    },
    setShowAddUntargetList({ commit }, status) {
      commit("SET_SHOW_ADDUNTARGETLIST", status);
    },
    setReloadList({ commit }, status) {
      commit("SET_RELOAD_LIST", status);
    },
    setMdmCode({ commit }, status) {
      commit("SET_MDM_CODE", status);
    },
    delMdmCode({ commit }, status) {
      commit("DEL_MDM_CODE", status);
    }
  }
};
export default user;
