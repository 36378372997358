<template>
  <div class="main">
    <div class="login col-b2">
      <!-- 目标/非目标切换 -->
      <div v-if="$store.getters.showTargetSwitch" class="switchBtn">
        <mt-button
          :class="[
            $store.getters.showUntargetDoctor
              ? 'mint-button--default'
              : 'mintbuttonclick',
          ]"
          @click="targetDoctor"
          >目标客户</mt-button
        >
        <mt-button
          :class="[
            $store.getters.showUntargetDoctor
              ? 'mintbuttonclick'
              : 'mint-button--default',
          ]"
          @click="unTargetDoctor"
          >非目标客户</mt-button
        >
      </div>
      <!-- 搜索框样式 -->
      <div
        v-if="inputStatus || $store.getters.searchUntargetList"
        class="input-group"
        @click="focus"
      >
        <div class="search_Input">
          <i class="mintui mintui-search"></i>
          <form action="javascript:return true">
            <mt-field
              id="main_search"
              v-model="value"
              type="search"
              placeholder="输入客户姓名"
              @keyup.enter.native.stop="search"
              @input="input"
            ></mt-field>
          </form>
        </div>
        <!-- <div v-if="historyStatus" class="history">
          <img src="@/assets/history.png" alt />
          <span>分享历史</span>
        </div> -->
      </div>
      <!-- 嵌套路由页面显示 -->
      <router-view
        v-if="$route.meta.keepAlive"
        @SSLSClick="SSLSClick"
      ></router-view>
      <router-view
        v-if="!$route.meta.keepAlive"
        @SSLSClick="SSLSClick"
      ></router-view>
      <!-- 搜索框样式结束 -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'indexPage',
  data() {
    return {
      value: '',
      doctorList: [],
      gobackStatus: null,
      showTargetSwitch: true,
      showUntargetDoctor: false,
    }
  },
  computed: {
    inputStatus() {
      return this.$store.getters.searchStatus
    },
    goback() {
      return this.$store.getters.go
    },
    path() {
      return this.$store.getters.path
    },
    historyStatus() {
      return this.$store.getters.historyImgStatus
    },
    showTargetDoctorSwitchBtn() {
      return this.$store.getters.showTargetSwitch
    },
  },
  watch: {
    goback(newVal, oldVal) {
      this.value = ''
      this.gobackStatus = newVal
    },
    showTargetDoctorSwitchBtn(val) {
      this.showTargetSwitch = val
    },
  },
  created() {
    this.value = ''
    this.targetDoctor()
    this.$store.dispatch('setShowUntargetDoctor', false)
  },
  mounted() {},
  beforeDestroy() {
    // this.$store.dispatch('setShowUntargetDoctor', false)
  },
  methods: {
    targetDoctor() {
      this.value = ''
      this.$router.push({ path: '/doctor/Nav' })
      this.$store.dispatch('setSearchStatus', true)
      this.$store.dispatch('setShowAddUntargetList', false)
      this.$store.dispatch('setShowUntargetDoctor', false)
      this.$store.dispatch('setGoBack', false)
      this.showUntargetDoctor = this.$store.getters.showUntargetDoctor
    },
    unTargetDoctor() {
      this.value = ''
      this.$router.push({ path: '/UntargetDoctor/UntargetNav' })
      this.$store.dispatch('setSearchStatus', true)
      this.$store.dispatch('setShowAddUntargetList', true)
      this.$store.dispatch('setShowUntargetDoctor', true)
      this.$store.dispatch('setGoBack', false)
      this.showUntargetDoctor = this.$store.getters.showUntargetDoctor
    },
    SSLSClick(e) {
      this.value = e.trim()
      this.search()
    },
    search(e) {
      this.value = this.value.trim()
      var test2 = /\S/
      if (!test2.test(this.value)) {
        this.value = ''
        return
      }
      if (!this.$store.getters.showTargetSwitch) {
        localStorage.setItem('keyWorld', this.value)

        this.$router.push({ name: 'SSJG' })
      } else {
        if (this.value) {
          localStorage.setItem('keyWorld', this.value)
          let temp = []
          if (!this.showUntargetDoctor) {
            temp = JSON.parse(localStorage.getItem('doctorSSLS') || '[]')
          } else if (this.showUntargetDoctor) {
            temp = JSON.parse(localStorage.getItem('shareSSLS') || '[]')
          }
          for (var i in temp) {
            if (this.value === temp[i]) {
              temp.splice(i, 1)
              temp.unshift(this.value)
              if (!this.showUntargetDoctor) {
                localStorage.setItem('doctorSSLS', JSON.stringify(temp))
                this.$router.push({ name: 'SSJG' })
              } else if (this.showUntargetDoctor) {
                localStorage.setItem('shareSSLS', JSON.stringify(temp))
                this.$router.push({ name: 'SSJG' })
              }
              return false
            }
          }
          if (temp.length === 10) {
            temp.pop()
          }
          temp.unshift(this.value)
          if (!this.showUntargetDoctor) {
            localStorage.setItem('doctorSSLS', JSON.stringify(temp))
            this.$router.push({ name: 'SSJG' })
          } else if (this.showUntargetDoctor) {
            localStorage.setItem('shareSSLS', JSON.stringify(temp))
            this.$router.push({ name: 'SSJG' })
          }
        }
      }
    },
    input(e) {
      if (e.trim() === '' && this.$store.getters.go) {
        this.focus()
      }
    },
    focus() {
      this.$router.push({ path: '/doctor/SSLS' })
    },
    back() {
      this.$store.dispatch('setSearchStatus', true)
      this.$router.push({ path: '/doctor/Nav' })
      this.$store.dispatch('setShowTargetSwitch', true)
      this.$store.dispatch('setShowAddUntargetList', false)
      this.$store.dispatch('setMdmCode', false)
      this.$store.dispatch('setGoBack', false)
      this.value = ''
    },
  },
}
</script>

<style lang="less">
.input-group {
  .mintui-field-error {
    padding-right: 10px;
  }
  .mint-field-core {
    padding-left: 0.6rem;
    height: 100%;
    font-size: 0.4rem;
    color: #999999;
    background: rgba(228, 231, 237, 1) !important;
  }
  .mint-cell-value {
    height: 0.95rem;
  }
  .mint-cell .mint-field .is-nolabel {
    height: 0.95rem !important;
  }
}

form {
  width: 100%;
}
.mint-search-list {
  z-index: -5;
}

.mintui-search {
  font-size: 0.5rem !important;
  padding-left: 5px;
  /* width: 70%;
  margin: 0 auto; */
}
.mint-searchbar-core {
  padding-left: 9px;
  font-size: 0.48rem;
}
.mint-searchbar-inner,
.mint-searchbar-core,
.mint-searchbar {
  background: #e4e7ed !important;
  color: #999999;
}
.back {
  /* margin-right: 10px; */
}
.history {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.history img {
  width: 20px;
  height: 20px;
}
.history span {
  font-size: 10px;
  -webkit-transform: scale(0.83);
}
.mint-searchbar-inner {
  height: 25/37.5rem !important;
  padding: 4/37.5rem 0;
}
.mint-searchbar {
  padding: 0 !important;
}
.switchBtn {
  // position: relative;
  padding-top: 20px;
  text-align: center;
  z-index: 9999;
}
.switchBtn .mint-button {
  width: 170px;
  height: 32px;
  color: #333333;
}
.switchBtn .mint-button--default {
  background: rgba(224, 242, 241, 1);
  box-shadow: 0px 2px 4px 0px rgba(12, 12, 13, 0.1);
}
.switchBtn .mintbuttonclick {
  background: rgba(255, 255, 255, 1);
  color: #1b4298;
}
#main_search {
  height: 0.95rem !important;
  border-radius: 0.95rem;
  background: #e4e7ed !important;
  margin-left: -0.53rem;
  flex: 1;
}
.mint-search {
  margin-left: 0px;
}
.mint-cell,
.mint-navbar {
  background: #f5f7fa !important;
  text-align: left;
}
.mint-tab-item-label {
  font-size: 0.4rem !important;
}
.tab_hospital .mint-tab-item-label {
  padding-left: 0.5rem !important;
  text-align: left;
}
.tab_bind .mint-tab-item-label {
  padding-right: 0.5rem !important;
  text-align: right;
}
.mint-tab-item {
  padding: 0 !important;
}
.navBox {
  width: 10rem;
}
.navCell {
  border-bottom: 1px solid #e4e7ed;
}
.mint-cell-wrapper {
  max-height: 40px !important;
  background-image: none;
}
</style>
<style scoped lang="less">
.search_Input {
  height: 36px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mintui-search {
  position: relative;
  top: 0px;
  left: 8px;
  z-index: 10;
  color: #909399;
  font-size: 20px;
}

.back {
  height: 10px;
  transform: rotatez(90deg);
  margin: 0 10px;
}
@screenWidth: 37.5;
.navBar {
  width: 9rem;
  margin: 0 auto;
  position: relative;
}
.navArrow {
  width: 0.32rem;
  height: 0.16rem;
  vertical-align: middle;
  position: relative;
  left: 0.3rem;
}
.main {
  width: 100%;
}

.login {
  z-index: 9999;
  height: 100%;
}
.login-btn2 {
  margin: auto;
  margin-top: 20px;
  z-index: 9999;
  width: 90%;
}
.el-button:focus,
.el-button:hover {
  background-color: black;
  color: white;
}
.input-group {
  // height: 0.95rem;
  // height: 5.24vh;
  width: 90%;
  margin: 0 auto;
  // margin-bottom:0.1rem;
  display: flex;
  padding: 16/37.5rem 0 6/37.5rem 0;
  align-items: center;
  justify-content: space-around;
}

.input {
  margin-bottom: 20px;
  background-color: white;
  padding-left: 20px;
  border-radius: 5px;
  padding-right: 10px;
  text-align: left;
  width: 100%;
  height: 48px;
  position: relative;
}
.input-front {
  float: left;
  margin-top: 13px;
  color: black;
}
.input-front2 {
  float: left;
  margin-top: 13px;
  color: #757575;
  margin-left: 10px;
  font-size: 16px;
}
.input-front3 {
  float: right;
  margin-top: 13px;
  color: black;
  margin-right: 10px;
  font-size: 16px;
}
.reg-img {
  width: 24px;
  margin-top: 11px;
  float: left;
}
.reg-img2 {
  width: 20px;
  margin-top: 13px;
  float: right;
}
.input-input {
  border: none;
  background-image: none;

  width: 80%;
  height: 48px;
  font-size: 16px;
  padding-left: 8px;
  outline: 0;
}
.reg-btn {
  background-color: black;
  border: none;
  width: 100%;
}
.log-btn {
  float: right;
  margin-top: 5px;
  height: 40px;
  color: black;
  text-decoration: underline;
}
.reg-btn-ver {
  position: absolute;
  top: 11px;
  right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.navCellBox {
  width: 10rem;
  position: absolute;
  z-index: 2;
}
</style>
