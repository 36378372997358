import Vue from "vue";
import Vuex from "vuex";
import Router from "vue-router";

import Login from "@/view/login/index";
import Error404 from "@/components/error/404";

//我的客户
import Doctor from "@/components/Doctor/Main";
import Ssjg from "@/components/Doctor/SSJG";
import Nav from "@/components/Doctor/Nav";
import Ssls from "@/components/Doctor/SSLS";
import CreatedQRCode from "@/components/Doctor/createdQRCode";

//知情同意链接
import CreatedPhoneQRCode from "@/components/common/PhoneQrcode";

//非目标客户
import UntargetNav from "@/components/Untarget/UntargetNav";
import UntargetDoctor from "@/components/Untarget/UntargetMain";
import UntargetSsjg from "@/components/Untarget/UntargetSSJG";
import UntargetSsls from "@/components/Untarget/UntargetSSLS";
import UntargetQRCode from "@/components/Untarget/createdUntargetQRCode";

//RC客户
import RcDoctor from "@/components/RC/RcMain";
import RcSsjg from "@/components/RC/RCSSJG";
import RcNav from "@/components/RC/RCNav";
import RcSsls from "@/components/RC/RCSSLS";
import CreatedRcQRCode from "@/components/RC/createdRcQRCode";

//差旅协议
import AirplaneHotelDoctor from "@/components/AirplaneHotel/AirplaneHotelMain";
import AirplaneHotelSsjg from "@/components/AirplaneHotel/AirplaneHotelSSJG";
import AirplaneHotelNav from "@/components/AirplaneHotel/AirplaneHotelNav";
import AirplaneHotelSsls from "@/components/AirplaneHotel/AirplaneHotelSSLS";
import AirplaneHotelQRCode from "@/components/AirplaneHotel/createdAirplaneHotelQRCode";

Vue.use(Router);
Vue.use(Vuex);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      title: "登录"
    },
    {
      path: "/doctor",
      component: Doctor,
      meta: {
        requiresAuth: true, // 要求验证的页面,在此情况下其子页面也会被验证.
        title: "我的客户"
      },
      name: "doctor",
      redirect: "/doctor/Nav",
      children: [
        {
          path: "SSJG",
          name: "SSJG",
          component: Ssjg,
          meta: {
            keepAlive: false,
            title: "我的客户"
          }
        },
        {
          path: "Nav",
          name: "Nav",
          component: Nav,
          meta: {
            keepAlive: true,
            title: "我的客户"
          }
        },
        {
          path: "SSLS",
          name: "SSLS",
          component: Ssls,
          meta: {
            keepAlive: false,
            title: "我的客户"
          }
        },
        {
          path: "createdQRCode",
          name: "createdQRCode",
          component: CreatedQRCode,
          meta: {
            title: "客户详情"
          }
        },
        {
          path: "createdPhoneQRCode",
          name: "createdPhoneQRCode",
          component: CreatedPhoneQRCode,
          meta: {
            title: "手机知情同意"
          }
        },
      ]
    },
    {
      path: "/RcDoctor",
      component: RcDoctor,
      meta: {
        requiresAuth: true, // 要求验证的页面,在此情况下其子页面也会被验证.
        title: "我的RC客户"
      },
      name: "RcDoctor",
      redirect: "/RcDoctor/RcNav",
      children: [
        {
          path: "RCSSJG",
          name: "RCSSJG",
          component: RcSsjg,
          meta: {
            keepAlive: false,
            title: "我的RC客户"
          }
        },
        {
          path: "RCNav",
          name: "RCNav",
          component: RcNav,
          meta: {
            keepAlive: true,
            title: "我的RC客户"
          }
        },
        {
          path: "RCSSLS",
          name: "RCSSLS",
          component: RcSsls,
          meta: {
            keepAlive: false,
            title: "我的RC客户"
          }
        },
        {
          path: "createdRcQRCode",
          name: "createdRcQRCode",
          component: CreatedRcQRCode,
          meta: {
            title: "客户详情"
          }
        }
      ]
    },
    {
      path: "/UntargetDoctor",
      component: UntargetDoctor,
      meta: {
        requiresAuth: true, // 要求验证的页面,在此情况下其子页面也会被验证.
        title: "我的非目标客户"
      },
      name: "UntargetDoctor",
      redirect: "/UntargetDoctor/UntargetNav",
      children: [
        {
          path: "UntargetSSJG",
          name: "UntargetSSJG",
          component: UntargetSsjg,
          meta: {
            keepAlive: false,
            title: "我的非目标客户"
          }
        },
        {
          path: "UntargetNav",
          name: "UntargetNav",
          component: UntargetNav,
          meta: {
            keepAlive: true,
            title: "我的非目标客户"
          }
        },
        {
          path: "UntargetSSLS",
          name: "UntargetSSLS",
          component: UntargetSsls,
          meta: {
            keepAlive: false,
            title: "我的非目标客户"
          }
        },
        {
          path: "UntargetQRCode",
          name: "UntargetQRCode",
          component: UntargetQRCode,
          meta: {
            title: "客户详情"
          }
        },
        {
          path: "createdUntargetPhoneQRCode",
          name: "createdUntargetPhoneQRCode",
          component: CreatedPhoneQRCode,
          meta: {
            title: "手机知情同意"
          }
        },
      ]
    },
    {
      path: "/AirplaneHotelDoctor",
      component: AirplaneHotelDoctor,
      meta: {
        requiresAuth: true, // 要求验证的页面,在此情况下其子页面也会被验证.
        title: "HCP差旅协议"
      },
      name: "AirplaneHotelDoctor",
      redirect: "/AirplaneHotelDoctor/AirplaneHotelNav",
      children: [
        {
          path: "AirplaneHotelSSJG",
          name: "AirplaneHotelSSJG",
          component: AirplaneHotelSsjg,
          meta: {
            keepAlive: false,
            title: "HCP差旅协议"
          }
        },
        {
          path: "AirplaneHotelNav",
          name: "AirplaneHotelNav",
          component: AirplaneHotelNav,
          meta: {
            keepAlive: true,
            title: "HCP差旅协议"
          }
        },
        {
          path: "AirplaneHotelSSLS",
          name: "AirplaneHotelSSLS",
          component: AirplaneHotelSsls,
          meta: {
            keepAlive: false,
            title: "HCP差旅协议"
          }
        },
        {
          path: "AirplaneHotelQRCode",
          name: "AirplaneHotelQRCode",
          component: AirplaneHotelQRCode,
          meta: {
            title: "HCP差旅协议"
          }
        },
      ]
    },
    {
      path: "*",
      name: "Not Found",
      component: Error404,
      meta: {
        title: "404"
      }
    }
  ]
});
