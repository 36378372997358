import Vue from 'vue'
import App from './App'
import Axios from 'axios'
import Vuex from 'vuex'
import MintUI from 'mint-ui'
import ElementUI from 'element-ui'
import router from './router'
import store from './store/index'

import './permission'
import './utils/urlParams.js'
import '@/assets/style/common.less'
import 'mint-ui/lib/style.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
var VueCookie = require('vue-cookie')

Vue.prototype.S4 = function() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}
Vue.prototype.guid = function() {
  return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
}

window.onresize = setHtmlFontSize
function setHtmlFontSize() {
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  const htmlDom = document.getElementsByTagName('html')[0]
  htmlDom.style.fontSize = htmlWidth / 10 + 'px'
}
setHtmlFontSize()

Es6Promise.polyfill()
Vue.use(MintUI)
Vue.use(ElementUI)
Vue.use(Vuex)
Vue.use(VueCookie)
Vue.config.productionTip = false
Vue.prototype.$axios = Axios

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
