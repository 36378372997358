const getters = {
  token: state => state.user.token,
  refresh: state => state.user.refresh,
  loginStatus: state => state.user.loginStatus,
  netError: state => state.user.NetworkError,
  path: state => state.user.path,
  historyImgStatus: state => state.user.historyImg,
  go: state => state.user.goback,
  showUntargetDoctor: state => state.user.showUntargetDoctor,
  showTargetSwitch: state => state.user.showTargetSwitch,
  searchStatus: state => state.user.searchStatus,
  searchUntargetList: state => state.user.searchUntargetList,
  reloadList: state => state.user.reloadList,
  mdmCode: state => state.user.mdmCode,
  delMdmCode: state => state.user.mdmCode
}
export default getters
