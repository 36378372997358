<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2024-07-17 17:57:39
 * @LastEditTime: 2024-08-07 10:48:01
 * @LastEditors: Ray
-->
<template lang="html">
  <div class="listError">
    <div class="">
      <img src="@/assets/loginError.png" alt=" " />
    </div>
    <div class="titel">
      <p style="color: #888a8e">当前列表下暂无客户!</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="css" scoped>
.listError {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listError > :first-child {
  margin-top: 2.6rem;
  margin-bottom: 1.61rem;
}
.listError div.netWorkError {
  margin: 1rem 0rem 0.6rem;
}
.netWorkError_titel {
  color: #000000;
  font-size: 0.5rem;
  font-weight: 500;
  margin-bottom: 1.8rem;
}
.listError .titel {
  /* // margin-top: 1.61rem; */
  text-align: center;
  width: 5.12rem;
  font-size: 0.5rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 2.2rem;
}
</style>
