<template lang="html">
  <div class="not_found">
    404 File Not Found
  </div>
</template>

<script>
export default {};
</script>
<style lang="css" scoped>
.not_found {
  font-size: 20px;
  font-weight: 400;
}
</style>
