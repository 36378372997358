<template>
  <div class="main">
    <airl-qrcode></airl-qrcode>
  </div>
</template>

<script>
import AirlQrcode from '@/components/AirplaneHotel/airplaneHotelQrcode/index'
export default {
  name: 'AirplaneHotelNav',
  components: {
    AirlQrcode,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    if(this.$store.getters.refresh){
      this.$store.dispatch('setRefresh', false)
      setTimeout(()=>{
        window.location.replace(window.location.href)
      },0)
    }
    this.$store.dispatch('setGoBack', false)
    this.$store.dispatch('setMdmCode', false)
  },
  mounted() {
    history.pushState(null, null, document.URL);
  },
  destroyed() {
  },
  methods: {
  },
}
</script>
<style>
</style>
<style lang="less" scoped >
.main-body {
  /* 加上这个才会有当数据充满整个屏幕，可以进行上拉加载更多的操作 */
  overflow: scroll;
}
.mint-loadmore{
  min-height: 85.5vh;
}
.pStyle {
  display: inline-block;
  vertical-align: middle;
  // padding-right: 0.3rem;

}
.spanStyle {
  border-left: 2px solid #1B4298;
  padding-right: 4px;
}
.titleActive {
  color: #1B4298;
  font-weight: 700;
}
.defaultActive {
  color: #37424a;
}
.mint-cell-title {
  font-size: 14px !important;
}
.animate {
  // margin-top: 16px;
  padding-top: 16px;
  position: normal;
  min-height: 300px;
}
.animateFixed {
  margin-top: 16px;
  margin-left: 18.5px;
  position: fixed;
}
.listError {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listError > :first-child {
  margin-top: 0.6rem;
  margin-bottom: 0.61rem;
}
.listError div.netWorkError {
  margin: 2rem 0rem 0.6rem;
}
.netWorkError_titel {
  color: #666666;
  font-size: 0.48rem;
  margin-bottom: 2.8rem;
}
.listError .titel {
  // margin-top: 1.61rem;
  text-align: center;
  width: 5.12rem;
  font-size: 0.43rem;
  color: #e82626;
  margin-bottom: 2.2rem;
}
.is-selected {
  color: #1B4298 !important;
}
.activeColor {
  font-size: 0.4rem;
  color: #1B4298;
  /* color:'#1B4298'!important; */
}
.defaultColor {
  font-size: 0.4rem;
  color: #37424a;
  display: block;
  padding: 0.27rem 0;
}
.mint-button-pram {
  width: 100%;
  text-align: left;
  /* border: 1px solid #F6F8FA; */
  padding: 0;
  font-size: 0.37rem;
  color: #37424a;
  -webkit-box-shadow: none;
}

#main_search {
  height: 100%;
  border-radius: 1000px;
  background: #e4e7ed !important;
}
.mint-navbar {
  width: 100% !important;
  margin: 0;
  padding: 0.27rem 0;
}
.mint-navbar .mint-tab-item.is-selected {
  border-bottom: none;
}
.mint-cell,
.mint-navbar {
  background: #f5f7fa !important;
  text-align: left;
}
.mint-tab-item-label {
  font-size: 0.4rem !important;
}
.navBox {
  width: 10rem;
  // height:5.2vh;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //  height: 30px;
  // padding: 0.27rem 0;
}
.navCell {
  border-bottom: 1px solid #e4e7ed;
}
.mint-cell-wrapper {
  background-image: none;
  padding: 0 0 0 0.32rem;
}
.model {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000;
  z-index: 5;
}
</style>
<style scoped lang="less">
@screenWidth: 37.5;
.navBar {
  width: 9rem;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  text-align: center;
}
.navArrow {
  width: 0.32rem;
  height: 0.16rem;
  vertical-align: middle;
  // position: relative;
  display: inline-block;
}
.main {
  width: 100%;
}

.login {
  /* background-color: #EEEEEE; */
  height: 100%;
}
.login-btn2 {
  margin: auto;
  margin-top: 20px;
  width: 90%;
}
.el-button:focus,
.el-button:hover {
  background-color: black;
  color: white;
}
/* input{
  border:none;
  background-color: transparent;
} */
.input-group {
  padding-top: 20px;
  margin: auto;
  width: 90%;
}

.input {
  /* border: 1px solid #E0E0E0; */
  margin-bottom: 20px;
  background-color: white;
  padding-left: 20px;
  border-radius: 5px;
  padding-right: 10px;
  text-align: left;
  width: 100%;
  height: 48px;
  position: relative;
}
.input-front {
  float: left;
  margin-top: 13px;
  color: black;
}
.input-front2 {
  float: left;
  margin-top: 13px;
  color: #757575;
  margin-left: 10px;
  font-size: 16px;
}
.input-front3 {
  float: right;
  margin-top: 13px;
  color: black;
  margin-right: 10px;
  font-size: 16px;
}
.reg-img {
  width: 24px;
  margin-top: 11px;
  float: left;
}
.reg-img2 {
  width: 20px;
  margin-top: 13px;
  float: right;
}
.input-input {
  border: none;
  background-image: none;

  width: 80%;
  height: 48px;
  font-size: 16px;
  padding-left: 8px;
  outline: 0;
}
.reg-btn {
  background-color: black;
  border: none;
  width: 100%;
}
.log-btn {
  float: right;
  margin-top: 5px;
  height: 40px;
  color: black;
  text-decoration: underline;
}
.reg-btn-ver {
  position: absolute;
  /* float: right; */
  top: 11px;
  right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.navCellBox {
  width: 10rem;
  position: absolute;
  z-index: 999;
}
// .addUntargetBtn {
//   margin-top: 16px;
//   text-align: center;
// }
// .addUntargetBtn .addUntargetBtnImg {
//   width: 36px;
// }
// .addUntargetBtn span {
//   color: #888888;
//   vertical-align: 15px;
// }
.backTop {
  position: fixed;
  z-index: 800;
  right: 0px;
  top: 0px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
