<template lang="html">
  <div class="main">
    <div v-if="!netError" class="qrCode_main">
      <div ref="wrapper" class="main-body">
        <div class=" ">
          <doctorCard :data="cardData" :qr-code="false"> </doctorCard>
          <!-- 二维码生成成功 -->
          <div v-if="qrCodeError" class="qrCode_img">
            <div class="img phone_img">
              <!-- <img v-if="qrImg" :src="qrImg" alt=" " /> -->
              <div class="qrcode" ref="qrCodeUrl"></div>
            </div>
            <div v-if="qrImg" class="title">
              <span>该二维码有效期到 {{ expireSeconds }}</span>
              <span>我已仔细阅读屏幕上方对应的客户信息，并确认无误。</span>
            </div>
          </div>
          <!-- 二维码生成失败 -->
          <div v-if="!qrCodeError" class="qrCode_img">
            <div class="img" @click="regeneratePhoneQRCode">
              <img src="@/assets/createQrError.png" alt=" " />
            </div>
            <div class="titleError">
              <span>二维码生成失败请刷新重试</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <net-work-error v-if="netError">
      <mt-button class="btn-param-color" @click="reload">重新加载</mt-button>
    </net-work-error>
  </div>
</template>
<script>
import NetWorkError from '@/components/error/netWorkError'
import doctorCard from '@/components/common/doctorCard'
import { Indicator } from 'mint-ui'
import QRCode from 'qrcodejs2'
import { createPhoneQRCode } from '@/api/api.js'
import moment from 'moment'
export default {
  components: {
    doctorCard,
    NetWorkError,
  },
  data() {
    return {
      qrCodeError: true,
      cardData: {},
      title: '返回',
      qrImg: '',
      expireSeconds: ' ',
      netWork: false,
      spliceTagList: [],
      ready: false,
      // firstData:{},
    }
  },
  computed: {
    netError() {
      return this.netWork
    },
  },
  created() {
    this.setScrollTop(0)
    this.$store.dispatch('setMdmCode', false)
    this.$store.dispatch('setShowAddUntargetList', false)
    this.$store.dispatch('setSearchStatus', false)
    // this.$nextTick(function(){
    //   this.createPhoneQrcode()
    // })
  },
  mounted() {
    const data = sessionStorage.getItem('doctorData')
    this.cardData = JSON.parse(data)
    this.regeneratePhoneQRCode()
  },
  beforeDestroy() {},
  destroyed() {
    Indicator.close()
    this.$store.dispatch('setSearchStatus', true)
    this.$store.dispatch('setShowTargetSwitch', true)
  },
  methods: {
    setScrollTop(scroll_top) {
      document.documentElement.scrollTop = scroll_top
      window.pageYOffset = scroll_top
      document.body.scrollTop = scroll_top
    },
    //返回上一步
    back() {
      const path = this.$route.query.path
      this.$store.dispatch('setShowTargetSwitch', true)
      this.$store.dispatch('setSearchStatus', true)
      if (path === 'Nav') {
        this.$router.push({ path: '/doctor' })
      } else if (path === 'SSJG') {
        this.$router.push({ path: '/doctor/SSJG' })
      } else if (path === 'UntargetNav') {
        this.$router.push({ path: '/UntargetDoctor' })
      } else if (path === 'UntargetSSJG') {
        this.$router.push({ path: '/UntargetDoctor/UntargetSSJG' })
      } else if (path === 'RCNav') {
        this.$router.push({ path: '/RcDoctor' })
      } else if (path === 'RCSSJG') {
        this.$router.push({ path: '/RCDoctor/RCSSJG' })
      }
    },
    reload() {
      this.regeneratePhoneQRCode()
    },
    // 生成二维码
    regeneratePhoneQRCode() {
      Indicator.open({
        text: '二维码生成中...',
        spinnerType: 'fading-circle',
      })
      // 请求参数
      const data = {
        mdmCode: null,
      }
      data.mdmCode = this.cardData.mdmCode
      createPhoneQRCode(data)
        .then((res) => {
          if (res === '超时' || res === '504') {
            this.qrCodeError = false
            Indicator.close()
            this.netWork = true
            return
          }
          if (res === 'token失效') {
            this.qrCodeError = false
            Indicator.close()
            this.$messagebox
              .alert(
                `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                '提示'
              )
              .then((action) => {
                window.location.href = '/login'
              })
            return
          }
          const data = res.data
          if (data && data.status === 1) {
            this.netWork = false
            this.qrCodeError = true
            this.qrImg = data.data.qrcodeUrl
            if (!this.qrcode) {
              this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: data.data.qrcodeUrl, // 需要转换为二维码的内容
                width: 170,
                height: 170,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H,
              })
            } else {
              this.qrcode.clear() // clear the code.
              this.qrcode.makeCode(data.data.qrcodeUrl) // make another code.
            }
            this.expireSeconds = moment
              .unix(data.data.expirationTime)
              .format('YYYY-MM-DD')
            setTimeout(() => {
              Indicator.close()
            }, 1000)
          } else {
            this.qrCodeError = false
            Indicator.close()
          }
        })
        .catch((error) => {
          this.netWork = false
          Indicator.close()
          this.qrCodeError = false
        })
    },
  },
}
</script>

<style lang="css" scoped>
.main-body {
  padding-top: 5vh;
  /* 加上这个才会有当数据充满整个屏幕，可以进行上拉加载更多的操作 */
  overflow: scroll;
}
.mint-loadmore {
  min-height: 95vh;
}
.qrCode_main {
  width: 90%;
  margin: 0 auto;
}
.qrCode_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.4rem;
  width: 180/37.5rem;
}
.qrCode_img .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.187rem;
}
.titleError {
  margin-top: 0.187rem;
  font-size: 0.43rem;
  color: #e82626;
}
.qrCode_img .title :first-child {
  color: #333333;
  font-weight: 500;
  font-size: 0.25rem;
  /* color:#333;
  font-weight: 500;
  font-size: .34rem; */
  margin-bottom: 0.08rem;
}
.qrCode_img .title :last-child {
  margin-top: 0.5333rem;
  color: #333333;
  font-weight: 500;
  font-size: 0.3rem;
  -webkit-transform: scale(0.83);
}
.qrCode_img .img {
  /* width: 5.33rem;
  height: 5.33rem; */
  padding: 0.55rem;
  background-color: #fff;
}
.qrCode_img .img img {
  width: 5.33rem;
  height: 5.33rem;
}
/* .qrCode_img .img .qrcode img {
  width: 4.33rem;
  height: 4.33rem;
  padding: 0.55rem;
} */
.hospitalCard {
  padding-top: 0.42rem;
  width: 100%;
  min-height: 32/37.5rem;
  margin: 0 auto 8/37.5rem;
  border-radius: 4px;
}
.back {
  width: 0.42666667rem;
  height: 0.21333333rem;
  transform: rotatez(90deg);
  margin: auto 0;
}
.collapse-content {
  min-height: 0.85333333rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}
.collapse-content .null-data {
  margin-bottom: 0.0533rem;
  text-align: center;
}
.collapse-content .interesting {
  margin-bottom: 0.08rem;
}
.collapse-content .interesting > div > span {
  border: 0.0267rem solid #1b4298;
  color: rgba(108, 83, 144, 1);
  background: rgba(0, 133, 124, 0.06);
  padding: 0px 0.08rem 0px 0.08rem;
  margin-right: 0.2667rem;
  display: inline-block;
  margin-bottom: 0.1867rem;
  border-radius: 0.1333rem;
}
.el-collapse-item img {
  width: 0.58666rem;
  padding-right: 0.1rem;
  padding-left: 0.1rem;
}
.collapse-content div {
  width: 100%;
  margin-bottom: 0px;
  font-size: 0.32rem;
}
.collapse-content .detail {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.collapse-content > div > span {
  display: inline-block;
  width: 2.6rem;
}
.phone_img {
  display: -webkit-box;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}
</style>
