<template lang="html">
  <div class="filterData ">
    <img src="@/assets/jurassic.png" alt="" />
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.filterData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filterData img {
  margin: 1.73rem 0 0.77rem;
}
.filterData .titel {
  /* width: 100%; */
  text-align: left;
  color: #000000;
  font-size: 0.5rem;
  font-weight: 500;
}
</style>
