<template lang="html">
  <div class="listError">
    <div class="netWorkError">
      <img src="@/assets/netWorkError.png" alt=" " />
    </div>
    <div class="netWorkError_titel">
      <p>当前网络状态不稳定，请重试</p>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
.listError {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listError > :first-child {
  margin-top: 0.6rem;
  margin-bottom: 1.61rem;
}
.listError div.netWorkError {
  margin: 1rem 0rem 0.6rem;
}
.netWorkError_titel {
  color: #000000;
  font-size: 0.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.listError .titel {
  /* // margin-top: 1.61rem; */
  text-align: left;
  width: 5.12rem;
  font-size: 0.5rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 2.2rem;
}
</style>
