import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getter'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState(
    {
      storage: window.sessionStorage
    }
  )],
  modules: {
    user
  },
  getters
})

export default store

