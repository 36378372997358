<template>
  <transition name="slide-fade">
    <div>
      <div class="qr-up" @click="showQrcode">
        <img :src="imgurl" alt=" " />
      </div>
      <div class="dialog">
        <el-dialog title="差旅协议二维码" style="text-align:center" :visible.sync="qrShow" :before-close="handleClose" >
          <div class="qrCode_img">
            <div class="img phone_img">
              <div class="qrcode" ref="qrCodeUrl"></div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- <div class="qrCode_img"><div class="img phone_img"><div class="qrcode" ref="qrCodeUrl"></div></div></div> -->
    </div>
  </transition>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  name: 'AirlQrcode',
  data() {
    return {
      imgurl: require('@/assets/airQrcode1.png'),
      qrShow: false,
      qrcode:''
    }
  },
  computed: {
  },
  mounted() {

  },
  destroyed() {
  },
  methods: {
    handleClose(){
      this.qrShow = false
      this.qrcode.clear();
    },
    createQrcode(){
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: process.env.VUE_APP_AUTH_URL+'/hcp/me/page/travelConsent', // 需要转换为二维码的内容
        width: 170,
        height: 170,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    showQrcode() {
      this.qrShow = true
      this.$nextTick(()=>{
        if(!this.qrcode){
          this.createQrcode()
        }
      })
    },
  }
}
</script>

<style lang="less">
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.3, 0.8, 1);
  opacity: 0;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active 在低于 2.1.8 版本中 */ {
  // transform: translateY(-20px);
  opacity: 0;
}
.qr-up {
  position: fixed;
  right: 50px;
  bottom: 125px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: whitesmoke;
  transition: 0.3s;
  box-shadow: 0px 4px 4px 2px rgba(12, 12, 13, 0.1);
  opacity: 1;
  z-index: 100;
  .el-icon-caret-top {
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
  }
  p {
    display: none;
    text-align: center;
    color: #fff;
  }
  &:hover {
    opacity: 1;
  }
}
.qr-up img {
  width: 80%;
  height: 80%;
  margin: 4px;
  border-radius: 50%;
}
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.el-dialog{
  width: 85%!important;
  margin-top: 30vh!important;
  border-radius: 10px!important;
}
.qrcode img{
  margin: auto;
}
.el-dialog__header{
  border-bottom: 0.026667rem solid #ddd;
}
</style>
