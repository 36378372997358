<template lang="html">
  <div class="listError">
    <div class="">
      <img src="@/assets/loginError.png" alt=" " />
    </div>
    <div class="titel">
      <p>未能成功获取您的医生列表请重新登录或刷新页面</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.listError {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listError > :first-child {
  margin-top: 0.6rem;
  margin-bottom: 0.61rem;
}
.listError div.netWorkError {
  margin: 1rem 0rem 0.6rem;
}
.netWorkError_titel {
  font-size: 0.5rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 1.8rem;
}
.listError .titel {
  /* // margin-top: 1.61rem; */
  text-align: left;
  width: 5.32rem;
  font-size: 0.5rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 2.2rem;
}
</style>
