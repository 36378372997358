<template>
  <div class="main">
    <!-- 导航栏样式 -->
    <div v-if="model" class="model" @click="clickModel"></div>
    <div v-if="!netError&&!error&&!($store.getters.showUntargetDoctor&&$store.getters.showTargetSwitch)" class="numberHcp">
      <span style="padding: 0 0 0 0.16rem;">您当前目标客户总数:</span>
      <mt-spinner v-if="allDoctorNumber===''" class="loadingSprinner" type="snake"></mt-spinner>
      <span v-else>{{ allDoctorNumber }}</span>
      <span style="margin-left:5px;">已绑定人数:</span>
      <mt-spinner v-if="validatedNumber===''" class="loadingSprinner" type="snake"></mt-spinner>
      <span v-else>{{ validatedNumber }}</span>
      <span style="margin-left:5px;">实名认证人数:</span>
      <mt-spinner v-if="consentStateNumber===''" class="loadingSprinner" type="snake"></mt-spinner>
      <span v-else>{{ consentStateNumber }}</span>
      <div style="margin-left:5px;font-size: 0.25rem;">(*仅供内部参考使用,不作为考核依据,请勿分发或转发。)</div>
    </div>
    <div class="navBox" @click="tabStatus()">
      <mt-navbar v-model="active" class="navBar">
        <mt-tab-item id="tab-container1" class="tab_hospital">
          <span @click="isShowTab('tab-container1')">
            <p class="pStyle">
              <span class="spanStyle"></span>
              <span :class="hospitalActive==0 ? 'defaultActive': 'titleActive'">{{ hospital }}</span>
            </p>
            <img
              class="navArrow"
              :src="imgurl"
              :style="active === 'tab-container1' ? ' ' : 'transform:rotatez(-90deg)' "
              alt=" "
            />
          </span>
        </mt-tab-item>
        <mt-tab-item id="tab-container3" class="tab_bind">
          <span @click="isShowTab('tab-container3')">
            <p class="pStyle">
              <span class="spanStyle"></span>
              <span :class="bindActive== 0 ? 'defaultActive' : 'titleActive' ">{{ bindTitle }}</span>
            </p>
            <img
              class="navArrow"
              :src="imgurl"
              :style="active === 'tab-container3' ? '' : 'transform:rotatez(-90deg)' "
              alt
            />
          </span>
        </mt-tab-item>
        <mt-tab-item id="tab-container2" class="tab_bind">
          <span @click="isShowTab('tab-container2')">
            <p class="pStyle">
              <span class="spanStyle"></span>
              <span :class="consentStateActive== 0 ? 'defaultActive' : 'titleActive' ">{{ consentStateTitle }}</span>
            </p>
            <img
              class="navArrow"
              :src="imgurl"
              :style="active === 'tab-container2' ? '' : 'transform:rotatez(-90deg)' "
              alt
            />
          </span>
        </mt-tab-item>
      </mt-navbar>
      <div class="navCellBox">
        <mt-tab-container v-model="active">
          <mt-tab-container-item id="tab-container1">
            <mt-cell v-for="(data,index) in jobData" :key="index" class="navCell">
              <span
                slot="title"
                :class="data.active?'activeColor':'defaultColor'"
                @click="screenArr(data,0)"
              >{{ data.titel }}</span>
            </mt-cell>
          </mt-tab-container-item>
          <mt-tab-container-item id="tab-container3">
            <mt-cell v-for="(data,index) in BDZTData" :key="index" class="navCell">
              <span
                slot="title"
                :class="data.active?'activeColor':'defaultColor'"
                @click="screenArr(data,2)"
              >{{ data.titel }}</span>
            </mt-cell>
          </mt-tab-container-item>
          <mt-tab-container-item id="tab-container2">
            <mt-cell v-for="(data,index) in consentStateData" :key="index" class="navCell">
              <span
                slot="title"
                :class="data.active?'activeColor':'defaultColor'"
                @click="screenArr(data,3)"
              >{{ data.titel }}</span>
            </mt-cell>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
      <!-- <div
        v-if="$store.getters.showUntargetDoctor&&$store.getters.showTargetSwitch"
        class="addUntargetBtn"
      >
        <img
          src="@/assets/addBtn.png"
          class="addUntargetBtnImg"
          alt="添加非目标客户"
          @click="addUntargetDoctor"
        />
        <span>添加非目标客户</span>
      </div> -->
    </div>
    <!-- 导航栏样式结束 -->
    <!-- 数据获取成功 -->
      <!-- style="{ height: (wrapperHeight-150) + 'px' }" -->
    <div
      v-if="loadShow&&!netError&&!error&&!filterData&&!nullData"
      ref="wrapper"
      class="main-body"
      style="{ height: 85.5 + 'vh' }"
    >
      <mt-loadmore ref="loadmore" :top-method="loadTop">
        <div
          v-if="!netError&&!error&&!filterData&&!nullData"
          :class="animateFixed ? 'animateFixed' : 'animate' "
        >
          <div v-for="(item,index) in doctor" :key="index">
            <hospitalCard :data="item" :is-show="item.isOpen"></hospitalCard>
          </div>
        </div>
      </mt-loadmore>
    </div>
    <div
      v-if="!netError&&!error&&!filterData&&!nullData&&!loadShow"
      :class="animateFixed ? 'animateFixed' : 'animate' "
    >
      <div v-for="(item,index) in doctor" :key="index">
        <hospitalCard :data="item" :is-show="item.isOpen"></hospitalCard>
      </div>
    </div>

    <!-- 数据获取成功 -->
    <!-- 数据获取失败 -->
    <!-- 服务器错 -->
    <server-error v-if="error">
      <mt-button class="btn-param-color" @click="reload">重新加载</mt-button>
    </server-error>
    <!-- 网络错误 -->
    <net-work-error v-if="netError">
      <mt-button class="btn-param-color" @click="reload">重新加载</mt-button>
    </net-work-error>
    <back-top :package=" 'animate' "></back-top>
    <!-- 数据获取失败 -->
    <!-- 未筛选到数据 -->
    <filterDataNull v-if="filterData&&!netError&&!error&&!nullData">
      <p class="titel">当前筛选条件下暂无客户!</p>
    </filterDataNull>
    <noList v-if="nullData&&!netError&&!error&&!filterData"></noList>
  </div>
</template>

<script>
import filterDataNull from '@/components/error/filterDataNull'
import noList from '@/components/error/noList'
import { Indicator } from 'mint-ui'
import hospitalCard from '@/components/common/hospitalCard'
import ServerError from '@/components/error/error'
import NetWorkError from '@/components/error/netWorkError'
import BackTop from '@/components/backTop/index'
import { getHcpList } from '@/api/api.js'
import { getUntargetHcpList } from '@/api/api.js'
export default {
  name: 'Nav',
  components: {
    hospitalCard,
    BackTop,
    ServerError,
    NetWorkError,
    noList,
    filterDataNull,
  },
  data() {
    return {
      imgurl: require('@/assets/Arrow.png'),
      loadShow: true,
      isScreen: false,
      row: null,
      id: null,
      // wrapperHeight: 0,
      nullData: false,
      success: true,
      netWork: false,
      serviceErr: false,
      hospital: '科室',
      filterData: false,
      animateFixed: false,
      scopTop: null,
      // showUntargetDoctor: null,
      bindTitle: '绑定状态',
      consentStateTitle: '实名认证',
      hostpitalAc: 0,
      bindAc: 0,
      consentStateAc: 0,
      active: 'tab-container0',
      //医院过滤条件
      jobData: [{ titel: '全部', active: true }],
      // 绑定状态
      BDZTData: [
        { titel: '全部', active: true },
        { titel: '已绑定', active: false },
        { titel: '未绑定', active: false },
      ],
      //实名状态
      consentStateData: [
        { titel: '全部', active: true },
        { titel: '已认证', active: false },
        { titel: '未认证', active: false },
      ],
      isShowAs: false,
      doctor: [],
      filterArr: ['全部', 2, 2], //筛选条件 0未绑定 1 绑定 2显示全部
      doctorList: [],
      allDoctorNumber: '',
      validatedNumber: '',
      consentStateNumber: '',
    }
  },
  computed: {
    hospitalActive() {
      return this.hostpitalAc
    },
    bindActive() {
      return this.bindAc
    },
    consentStateActive(){
      return this.consentStateAc
    },
    netError() {
      return this.netWork
    },
    error() {
      return this.serviceErr
    },
    showUntargetDoctorBtn() {
      this.hostpitalAc = 0
      this.bindAc = 0
      this.hospital = '科室'
      this.bindTitle = '绑定状态'
      this.filterArr = ['全部', 2, 2]
      this.row = null
      this.id = null
      this.isScreen = false
      return this.$store.getters.showUntargetDoctor
    },
    showReloadList() {
      return this.$store.getters.reloadList
    },
    model() {
      if (
        this.active === 'tab-container1' ||
        this.active === 'tab-container2' ||
        this.active === 'tab-container3'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    showUntargetDoctorBtn(val) {
      this.reload()
    },
    showReloadList() {
      this.initList()
      this.reload()
    },
  },
  created() {
    if(this.$store.getters.refresh){
      this.$store.dispatch('setRefresh', false)
      setTimeout(()=>{
        window.location.replace(window.location.href)
      },0)
    }
    this.setScrollTop(0)
    this.$store.dispatch('setShowTargetSwitch', true)
    this.$store.dispatch('setGoBack', false)
    this.$store.dispatch('setMdmCode', false)
    this.$store.dispatch('setShowAddUntargetList', true)
  },
  mounted() {
    // this.wrapperHeight =
    //   document.documentElement.clientHeight -
    //   this.$refs.wrapper.getBoundingClientRect().top
    this.getHcpListData()
    window.addEventListener('scroll', this.handleScroll, false)
    history.pushState(null, null, document.URL);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    Indicator.close()
  },
  methods: {
    handleScroll() {
      this.scrollTop = this.getScrollTop()
      if (this.scrollTop >= 1) {
        this.loadShow = false
      } else {
        if(!this.netError&&!this.error&&!this.filterData&&!this.nullData){
          this.loadShow = true
        }else{
          this.loadShow = false
        }
      }
    },
    getScrollTop() {
      const scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      return scrollTop
    },
    setScrollTop(scroll_top) {
      document.documentElement.scrollTop = scroll_top
      window.pageYOffset = scroll_top
      document.body.scrollTop = scroll_top
    },
    loadTop() {
      this.scrollTop = this.getScrollTop()
      if (this.scrollTop <= 1) {
        this.setScrollTop(0)
        this.isScreen = true
        this.reload()
      } else {
        this.$refs.loadmore.onTopLoaded()
        return false
      }
    },
    // 获取HCP列表
    getHcpListData() {
      Indicator.open({
        text: '数据加载中...',
        spinnerType: 'fading-circle',
      })
      const data = {
        offset: -1,
        timestamp: Date.now(),
      }
      this.animateFixed = false
      this.doctorList = []
      if (this.$store.getters.showUntargetDoctor) {
        this.initList()
        getUntargetHcpList(data)
          .then((res) => {
            if (res === '超时' || res === '504') {
              Indicator.close()
              this.netWork = true
              this.serviceErr = false
              return
            }
            if (res === 'token失效') {
              this.serviceErr = true
              this.netWork = false
              Indicator.close()
              this.$messagebox
                .alert(
                  `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                  '提示'
                )
                .then((action) => {
                  window.location.href = '/login'
                })
              return
            }
            const data = res.data
            if (data && data.status === 1) {
              if (this.$refs.loadmore) {
                this.$refs.loadmore.onTopLoaded()
              }
              this.netWork = false
              this.serviceErr = false
              this.nullData = false
              Indicator.close()
              if (!data.data.length) {
                this.nullData = true
                this.validatedNumber = 0
                this.allDoctorNumber = 0
                this.consentStateNumber = 0
                return
              }
              const list = data.data
              for (const i in list) {
                if (!list[i].validateState) {
                  list[i].validateState = 0
                }
                if (!list[i].isFollow) {
                  list[i].isFollow = 0
                }
              }
              // 将返回数据进行分组
              const dest = this.grouping(list, 'workplaceName')
              this.doctorList = dest
              // 生成医院过滤条件
              const jobFilter = []
              dest.forEach((item) => {
                item.doctors.forEach((item2) => {
                  const obj = {
                    titel: '',
                    active: false,
                  }
                  obj.titel = item2.department
                  let hasObj = false
                  for (const i in jobFilter) {
                    if (jobFilter[i].titel === obj.titel) {
                      hasObj = true
                      return
                    }
                  }
                  if (!hasObj) {
                    jobFilter.push(obj)
                  }
                })
              })
              this.jobData = this.jobData.concat(jobFilter)
              this.doctor = JSON.parse(JSON.stringify(this.doctorList))
              if (this.isScreen) {
                this.screenArr(this.row, this.id)
              }
            } else {
              Indicator.close()
              this.serviceErr = true
              this.netWork = false
              return
            }
          })
          .catch((error) => {
            this.serviceErr = true
            this.netWork = false
            Indicator.close()
            return
          })
      } else {
        // 加载目标客户列表
        this.initList()
        getHcpList(data)
          .then((res) => {
            if (res === '超时' || res === '504') {
              Indicator.close()
              this.netWork = true
              this.serviceErr = false
              return
            }
            if (res === 'token失效') {
              this.serviceErr = true
              this.netWork = false
              Indicator.close()
              this.$messagebox
                .alert(
                  `<div style="text-align:center;line-height:1;color:#888888;font-size:0.4rem;">登陆失效，请重新登录</div>`,
                  '提示'
                )
                .then((action) => {
                  window.location.href = '/login'
                })
              return
            }
            const data = res.data
            if (data && data.status === 1) {
              if (this.$refs.loadmore) {
                this.$refs.loadmore.onTopLoaded()
              }
              this.netWork = false
              this.serviceErr = false
              this.nullData = false
              Indicator.close()
              if (!data.data.length) {
                this.nullData = true
                this.validatedNumber = 0
                this.allDoctorNumber = 0
                this.consentStateNumber = 0
                return
              }
              const list = data.data
              for (const i in list) {
                if (!list[i].validateState) {
                  list[i].validateState = 0
                }
                if (!list[i].isFollow) {
                  list[i].isFollow = 0
                }
                if (!list[i].consentState) {
                  list[i].consentState = 0
                }
              }
              // 将返回数据进行分组
              // 已绑定医生数量，医生总量
              this.validatedNumber = list.filter(item => item.validateState === 'validated').length
              this.consentStateNumber = list.filter(item => item.consentState == '1').length
              this.allDoctorNumber = list.length
              const dest = this.grouping(list, 'workplaceName')
              this.doctorList = dest
              // 生成医院过滤条件
              const jobFilter = []
              dest.forEach((item) => {
                item.doctors.forEach((item2) => {
                  const obj = {
                    titel: '',
                    active: false,
                  }
                  obj.titel = item2.department
                  let hasObj = false
                  for (const i in jobFilter) {
                    if (jobFilter[i].titel === obj.titel) {
                      hasObj = true
                      return
                    }
                  }
                  if (!hasObj) {
                    jobFilter.push(obj)
                  }
                })
              })
              this.jobData = this.jobData.concat(jobFilter)
              this.doctor = JSON.parse(JSON.stringify(this.doctorList))
              if (this.isScreen) {
                this.screenArr(this.row, this.id)
              }
            } else {
              this.serviceErr = true
              this.netWork = false
              Indicator.close()
              return
            }
          })
          .catch((error) => {
            this.serviceErr = true
            this.netWork = false
            Indicator.close()
            return
          })
      }
    },
    reload() {
      this.animateFixed = false
      this.active = ' '
      this.setScrollTop(0)
      this.getHcpListData()
    },
    //初始化列表
    initList() {
      this.nullData = false
      this.filterData = false
      this.serviceErr = false
      this.doctor = []
      this.jobData = [{ titel: '全部', active: true }]
      this.BDZTData = [
        { titel: '全部', active: true },
        { titel: '已绑定', active: false },
        { titel: '未绑定', active: false },
      ]
      this.consentStateData = [
        { titel: '全部', active: true },
        { titel: '已认证', active: false },
        { titel: '未认证', active: false },
      ]

    },
    // 分组
    grouping(arr, prop) {
      var map = {}
      var dest = []
      // 对数据进行分组
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i]
        if (!map[ai[prop]]) {
          dest.push({
            HospitalName: ai[prop],
            isOpen: false,
            doctors: [ai],
          })
          map[ai[prop]] = ai
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j]
            if (dj.HospitalName === ai[prop]) {
              dj.doctors.push(ai)
              break
            }
          }
        }
      }
      return dest
    },
    clickModel() {
      this.active = ' '
      this.animateFixed = false
    },
    screenArr(row, id) {
      this.animateFixed = false
      this.row = row
      this.id = id
      this.filterData = false
      this.doctor = JSON.parse(JSON.stringify(this.doctorList))
      if (id === 0) {
        this.jobData.forEach((item) => {
          if (item.titel === row.titel) {
            item.active = true
          } else {
            item.active = false
          }
        })
        this.filterArr[0] = row.titel
      } else if (id === 2) {
        this.BDZTData.forEach((item) => {
          if (item.titel === row.titel) {
            item.active = true
          } else {
            item.active = false
          }
        })
        if (row.titel === '全部') {
          this.bindAc = 0
          this.bindTitle = '绑定状态'
          this.filterArr[2] = 2
        } else if (row.titel === '已绑定') {
          this.bindAc = 2
          this.bindTitle = '已绑定'
          this.filterArr[2] = 'validated'
        } else if (row.titel === '未绑定') {
          this.bindTitle = '未绑定'
          this.bindAc = 2
          this.filterArr[2] = 0
        }
      } else if (id === 3) {
        this.consentStateData.forEach((item) => {
          if (item.titel === row.titel) {
            item.active = true
          } else {
            item.active = false
          }
        })
        if (row.titel === '全部') {
          this.consentStateAc = 0
          this.consentStateTitle = '认证状态'
          this.filterArr[1] = 2
        } else if (row.titel === '已认证') {
          this.consentStateAc = 2
          this.consentStateTitle = '已认证'
          this.filterArr[1] = '1'
        } else if (row.titel === '未认证') {
          this.consentStateTitle = '未认证'
          this.consentStateAc = 2
          this.filterArr[1] = 0
        }
      }
      const departmentName = this.filterArr[0]
      const bindStatus = this.filterArr[2]
      const consentStateStatus = this.filterArr[1]
      let filterArr1 = []
      if (departmentName !== '全部') {
        this.hostpitalAc = 2
        this.hospital = departmentName.slice(0, 4) + '...'
        //此处修改
        filterArr1 = this.doctor
        filterArr1.forEach((item) => {
          const doctorsArr = item.doctors.filter((item1) => {
            return item1.department === departmentName
          })
          item.doctors = doctorsArr
        })
      } else {
        this.hostpitalAc = 0
        this.hospital = '科室'
        filterArr1 = this.doctor
      }
      const departmentFilterArr = JSON.parse(JSON.stringify(filterArr1))
      // 筛选是否绑定
      if (bindStatus !== 2) {
        departmentFilterArr.forEach((item) => {
          const doctorsArr = item.doctors.filter(
            (item1) => item1.validateState === bindStatus
          )
          item.doctors = doctorsArr
        })
      }
      console.log('departmentFilterArr',departmentFilterArr)
      //筛选是否认证
      const consentStateFilterArr = JSON.parse(JSON.stringify(departmentFilterArr))
      if (consentStateStatus !== 2) {
        consentStateFilterArr.forEach((item) => {
          const doctorsArr = item.doctors.filter(
            (item1) => item1.consentState == consentStateStatus
          )
          item.doctors = doctorsArr
        })
      }

      const filterArr3 = JSON.parse(JSON.stringify(consentStateFilterArr))
      //对过滤的结果进行筛选
      const filter = []
      filterArr3.forEach((item) => {
        if (item.doctors.length !== 0) {
          if (this.hostpitalAc !== 0 || this.bindAc !== 0 || this.consentStateAc !== 0) {
            item.isOpen = true
          }
          filter.push(item)
        }
      })
      // 筛选的结果为空，进行相应的操作
      if (filter.length === 0) {
        this.doctor = []
        if (!this.nullData && !this.netError && !this.Error) {
          this.filterData = true
        }
      } else {
        this.doctor = filter
      }
      this.active = ' '
    },
    isShowTab(e) {
      this.animateFixed = true
      if (e === this.active) {
        setTimeout(() => {
          this.isShowTab(2)
        }, 100)
      } else if (e === 2) {
        this.animateFixed = false
        this.active = 'tab-container4'
      }
    },
    tabStatus() {
      if (this.animateFixed === false) {
        this.animateFixed = false
        this.active = 'tab-container4'
      }
    },
    addUntargetDoctor() {
      // 跳转到添加非目标客户页面
      this.$store.dispatch('setShowTargetSwitch', false)
      this.$store.dispatch('setShowAddUntargetList', true)
      this.$store.dispatch('setSearchStatus', true)
      this.$router.push({ path: '/doctor/SSLS' })
    },
  },
}
</script>
<style>
</style>
<style lang="less" scoped >
.numberHcp{
  text-align: left;
  // text-align: center;
  padding: 0 5%;
  position: relative;
  z-index: 10;
  background-color: #F5F7FA;
  color: #1B4298;
  font-size: 0.30rem;
}
.loadingSprinner{
  font-size: 0.30rem;
  display: inline-block;
  vertical-align: bottom;
}
.main-body {
  /* 加上这个才会有当数据充满整个屏幕，可以进行上拉加载更多的操作 */
  overflow: scroll;
}
.mint-loadmore{
  min-height: 85.5vh;
}
.pStyle {
  display: inline-block;
  vertical-align: middle;
  // padding-right: 0.3rem;

}
.spanStyle {
  border-left: 2px solid #1B4298;
  padding-right: 4px;
}
.titleActive {
  color: #1B4298;
  font-weight: 700;
}
.defaultActive {
  color: #37424a;
}
.mint-cell-title {
  font-size: 14px !important;
}
.animate {
  // margin-top: 16px;
  padding-top: 16px;
  position: normal;
  min-height: 300px;
  padding-bottom: 30px;
}
.animateFixed {
  margin-top: 16px;
  margin-left: 18.5px;
  position: fixed;
}
.listError {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listError > :first-child {
  margin-top: 0.6rem;
  margin-bottom: 0.61rem;
}
.listError div.netWorkError {
  margin: 2rem 0rem 0.6rem;
}
.netWorkError_titel {
  color: #666666;
  font-size: 0.48rem;
  margin-bottom: 2.8rem;
}
.listError .titel {
  // margin-top: 1.61rem;
  text-align: center;
  width: 5.12rem;
  font-size: 0.43rem;
  color: #e82626;
  margin-bottom: 2.2rem;
}
.is-selected {
  color: #1B4298 !important;
}
.activeColor {
  font-size: 0.4rem;
  color: #1B4298;
  /* color:'#1B4298'!important; */
}
.defaultColor {
  font-size: 0.4rem;
  color: #37424a;
  display: block;
  padding: 0.27rem 0;
}
.mint-button-pram {
  width: 100%;
  text-align: left;
  /* border: 1px solid #F6F8FA; */
  padding: 0;
  font-size: 0.37rem;
  color: #37424a;
  -webkit-box-shadow: none;
}

#main_search {
  height: 100%;
  border-radius: 1000px;
  background: #e4e7ed !important;
}
.mint-navbar {
  width: 100% !important;
  margin: 0;
  padding: 0.27rem 0;
}
.mint-navbar .mint-tab-item.is-selected {
  border-bottom: none;
}
.mint-cell,
.mint-navbar {
  background: #f5f7fa !important;
  text-align: left;
}
.mint-tab-item-label {
  font-size: 0.4rem !important;
}
.navBox {
  width: 10rem;
  // height:5.2vh;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //  height: 30px;
  // padding: 0.27rem 0;
}
.navCell {
  border-bottom: 1px solid #e4e7ed;
}
.mint-cell-wrapper {
  background-image: none;
  padding: 0 0 0 0.32rem;
}
.model {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000;
  z-index: 5;
}
</style>
<style scoped lang="less">
@screenWidth: 37.5;
.navBar {
  width: 9rem;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  text-align: center;
}
.navArrow {
  width: 0.32rem;
  height: 0.16rem;
  vertical-align: middle;
  // position: relative;
  display: inline-block;
}
.main {
  width: 100%;
}

.login {
  /* background-color: #EEEEEE; */
  height: 100%;
}
.login-btn2 {
  margin: auto;
  margin-top: 20px;
  width: 90%;
}
.el-button:focus,
.el-button:hover {
  background-color: black;
  color: white;
}
/* input{
  border:none;
  background-color: transparent;
} */
.input-group {
  padding-top: 20px;
  margin: auto;
  width: 90%;
}

.input {
  /* border: 1px solid #E0E0E0; */
  margin-bottom: 20px;
  background-color: white;
  padding-left: 20px;
  border-radius: 5px;
  padding-right: 10px;
  text-align: left;
  width: 100%;
  height: 48px;
  position: relative;
}
.input-front {
  float: left;
  margin-top: 13px;
  color: black;
}
.input-front2 {
  float: left;
  margin-top: 13px;
  color: #757575;
  margin-left: 10px;
  font-size: 16px;
}
.input-front3 {
  float: right;
  margin-top: 13px;
  color: black;
  margin-right: 10px;
  font-size: 16px;
}
.reg-img {
  width: 24px;
  margin-top: 11px;
  float: left;
}
.reg-img2 {
  width: 20px;
  margin-top: 13px;
  float: right;
}
.input-input {
  border: none;
  background-image: none;

  width: 80%;
  height: 48px;
  font-size: 16px;
  padding-left: 8px;
  outline: 0;
}
.reg-btn {
  background-color: black;
  border: none;
  width: 100%;
}
.log-btn {
  float: right;
  margin-top: 5px;
  height: 40px;
  color: black;
  text-decoration: underline;
}
.reg-btn-ver {
  position: absolute;
  /* float: right; */
  top: 11px;
  right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.navCellBox {
  width: 10rem;
  position: absolute;
  z-index: 999;
}
.addUntargetBtn {
  margin-top: 16px;
  text-align: center;
}
.addUntargetBtn .addUntargetBtnImg {
  width: 36px;
}
.addUntargetBtn span {
  color: #888888;
  vertical-align: 15px;
}
.backTop {
  position: fixed;
  z-index: 800;
  right: 0px;
  top: 0px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
